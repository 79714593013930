import { ApiService, Authentication } from '../../common'
import { Analytics } from '@one/types'
import { AxiosPromise } from 'axios'

export class AnalyticsAppApi extends ApiService {
  getRecommendationForProduct(
    productId: string
  ): AxiosPromise<Analytics.Responses.RecommendationProducts> {
    return this.get(`/personalize/get-recommendations-for-product`, {
      params: {
        productId
      },
      authentication: Authentication.REQUIRED
    })
  }
}
