import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { AppDocumentNumbersApi } from './app'
import { BackofficeDocumentNumbersApi } from './backoffice'
import { Queue } from '../../queue'

export class DocumentNumbers implements ApiNamespaces {
  app: AppDocumentNumbersApi
  backoffice: BackofficeDocumentNumbersApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new AppDocumentNumbersApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
    this.backoffice = new BackofficeDocumentNumbersApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
