import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { BackofficeWebhooksApi } from './backoffice'
import { AppWebhooksApi } from './app'
import { Queue } from '../../queue'

export class Webhooks implements ApiNamespaces {
  app: AppWebhooksApi
  backoffice: BackofficeWebhooksApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new AppWebhooksApi(name, sessionId, requestsQueue, options)

    this.backoffice = new BackofficeWebhooksApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
