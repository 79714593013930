import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Comments } from '@one/types'
import AttachmentsUploadUrlApplicationView = Comments.Backoffice.AttachmentUploadUrlApplicationView
import AddCommentsRestCommand = Comments.Backoffice.Requests.AddCommentsRestCommand
import UpdateCommentRestCommand = Comments.Backoffice.Requests.UpdateCommentRestCommand
import AttachmentDownloadUrlRestView = Comments.Backoffice.Responses.AttachmentDownloadUrlRestView
import AddAttachmentsRestCommand = Comments.Backoffice.Requests.AddAttachmentsRestCommand
import DiscussionItem = Comments.Backoffice.DiscussionItem

export class BackofficeCommentsApi extends ApiServiceWithSettings {
  getDiscussion(id: string): AxiosPromise<DiscussionItem> {
    return this.get(`/discussions/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  addAttachment(
    id: string,
    attachments: AddAttachmentsRestCommand
  ): AxiosPromise<AttachmentsUploadUrlApplicationView> {
    return this.post(`/discussions/${id}/attachments`, attachments, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  addComment(
    id: string,
    comments: AddCommentsRestCommand
  ): AxiosPromise<{ commentIds: Array<string> }> {
    return this.post(`/discussions/${id}/comments`, comments, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateComment(
    id: string,
    commentId: string,
    comment: UpdateCommentRestCommand
  ): AxiosPromise<void> {
    return this.patch(`/discussions/${id}/comments/${commentId}`, comment, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteComment(
    id: string,
    commentId: string,
    documentType: string
  ): AxiosPromise<void> {
    return this.delete(
      `/discussions/${id}/${documentType}/comments/${commentId}`,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  unlinkAttachment(
    id: string,
    attachmentId: string,
    documentType: string
  ): AxiosPromise<void> {
    return this.post(
      `/discussions/${id}/${documentType}/attachments/${attachmentId}/unlink`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  deleteAttachment(
    id: string,
    attachmentId: string,
    documentType: string
  ): AxiosPromise<void> {
    return this.delete(
      `/discussions/${id}/${documentType}/attachments/${attachmentId}`,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  uploadAttachment(
    uploadUrl: string,
    formData: any,
    fileType: string
  ): AxiosPromise<void> {
    return this.put(uploadUrl, formData, {
      rawUrl: true,
      injectToken: false,
      injectTenant: false,
      headers: {
        'Content-Type': fileType
      }
    })
  }

  finishUpload(url: string): AxiosPromise<void> {
    return this.post(url, null, {
      rawUrl: true,
      injectToken: false,
      injectTenant: false
    })
  }

  generateAttachmentUrl(
    id: string,
    documentType: string,
    attachmentId: string
  ): AxiosPromise<AttachmentDownloadUrlRestView> {
    return this.get(
      `/discussions/${id}/${documentType}/attachments/${attachmentId}/download-url`,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
}
