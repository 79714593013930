import axios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'

import uuid from 'uuid-random'
import { cloneDeep } from 'lodash-es'
import { APICache, CachePolicy } from './cache'
import { CMS } from '@one/types'
import { Queue } from './queue'
/*
  /api/<version>/<service>/<audience>/<authorization>/

  GET /api/v1/pricing/app/auth-optional/prices
  POST /api/v1/notif/internal/notification
  GET /api/v1/account/integrations/organization/{id}


  api.pricing.get('/prices', { auth: Auth.REQUIRED }) -> /api/v1/pricing/app/auth-optional/prices
 */

const ONE_TENANT_HEADER_NAME = 'one-tenant'

export enum Version {
  V1 = 'v1'
}

export enum Authentication {
  PUBLIC = 'public',
  OPTIONAL = 'auth-optional',
  REQUIRED = 'auth-required'
}

export enum Audience {
  APP = 'app',
  INTERNAL = 'internal',
  BACKOFFICE = 'backoffice'
}

export interface ApiInstances {
  [key: string]: ApiNamespaces
}

export interface OneAPIOptions {
  version: Version
  baseURL: string
  tenantKey: string
  cacheEnabled: boolean
  queueEnabled: boolean
  getTenantKey?(): string
  isTokenValid?(token: string): boolean
  onResponseError?(): Promise<any>
  triggerTokenRefresh?(): void
  prefix: string
  getToken?(): string
  token?: string
  protocol?: string
  verbose?: boolean
  debug?: boolean
  timing?: boolean
  useRestPrefix?: boolean
  servicesOptions?: Record<string, OneAPIOptions>
}

export interface ServiceRequestConfig {
  rawUrl?: boolean
  authentication: Authentication
  injectToken: boolean
  injectTenant: boolean
  aud: Audience
  cache?: CachePolicy | boolean
}

export interface RequestConfig extends AxiosRequestConfig {
  rawUrl?: boolean
  authentication?: Authentication
  injectToken?: boolean
  injectTenant?: boolean
  aud?: Audience
  cache?: CachePolicy | boolean
}

export interface IQueuedRequest {
  config: RequestConfig
  resolve: Function
}

export class ApiService {
  sessionId: string
  name: string = ''
  baseURL: string = ''
  prefix: string = 'api'
  version: Version = Version.V1
  protocol: string = 'http'
  axios: AxiosInstance
  cacheEnabled: boolean = true
  queueEnabled: boolean = true
  useRestPrefix: boolean = false
  defaultRequestOptions: RequestConfig = {
    authentication: Authentication.PUBLIC,
    aud: Audience.APP,
    rawUrl: false,
    data: null
  }
  cache: APICache = new APICache()
  rQueue: Queue<IQueuedRequest>

  constructor(
    name: string,
    sessionId: string,
    rQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.name = name
    this.sessionId = sessionId
    this.axios = axios.create()
    this.rQueue = rQueue
    if (options) this.setConfig(options)
  }

  invalidate = this.cache.invalidate
  invalidateAll = this.cache.invalidateAll

  getToken(): string {
    throw new Error('Method not implemented.')
  }
  getTenantKey(): string {
    throw new Error('Method not implemented.')
  }
  isTokenValid(token: string): boolean {
    throw new Error('Method not implemented.')
  }
  triggerTokenRefresh(): void {
    throw new Error('Method not implemented.')
  }
  private onResponseError = (error: any): Promise<any> => {
    return Promise.reject(error)
  }

  public setConfig(options: OneAPIOptions) {
    this.setInterceptors(options)
    if (options.version) {
      this.version = options.version
    }
    if (options.protocol) {
      this.protocol = options.protocol.endsWith('://')
        ? options.protocol.substr(0, options.protocol.length - 3)
        : options.protocol
    }
    this.baseURL = options.baseURL
    if (options.useRestPrefix) {
      this.useRestPrefix = true
    }
    if (options.getToken) {
      this.getToken = options.getToken
    } else if (options.token) {
      this.getToken = (): string => options.token || ''
    }
    if (options.getTenantKey) {
      this.getTenantKey = options.getTenantKey
    } else if (options.tenantKey) {
      this.getTenantKey = (): string => options.tenantKey || ''
    }
    if (options.onResponseError) {
      this.onResponseError = options.onResponseError
    }
    if (options.triggerTokenRefresh) {
      this.triggerTokenRefresh = options.triggerTokenRefresh
    }
    if (options.isTokenValid) {
      this.isTokenValid = options.isTokenValid
    }
    if (options.prefix) {
      this.prefix = options.prefix
    }
    if (typeof options.cacheEnabled !== 'undefined') {
      this.cacheEnabled = options.cacheEnabled
    }
    if (typeof options.queueEnabled !== 'undefined') {
      this.queueEnabled = options.queueEnabled
    }
    if (this.baseURL && this.baseURL.trim()) {
      if (this.baseURL.endsWith('/')) {
        // Removing / from baseURL
        this.baseURL = this.baseURL.substr(0, this.baseURL.length - 1)
      }
      this.axios.defaults.baseURL = this.baseURL
    }
    if (options.servicesOptions && options.servicesOptions[this.name]) {
      const extendedConfig: OneAPIOptions = {
        ...options,
        ...options.servicesOptions[this.name]
      }
      delete extendedConfig.servicesOptions
      this.setConfig(extendedConfig)
    }
  }

  private setInterceptors(options: OneAPIOptions) {
    // @ts-ignore
    this.axios.interceptors.request.handlers = []
    // @ts-ignore
    this.axios.interceptors.response.handlers = []
    if (options && options.timing) {
      this.axios.interceptors.request.use(this.requestTimingInterceptor)
      this.axios.interceptors.response.use(this.responseTimingInterceptor)
    }
    // @ts-ignore
    if (options && options.debug) {
      this.axios.interceptors.request.use(this.debugRequestInterceptor)
      this.axios.interceptors.response.use(this.debugResponseInterceptor)
    } else if (options && options.verbose) {
      this.axios.interceptors.request.use(this.verboseRequestInterceptor)
      this.axios.interceptors.response.use(this.verboseResponseInterceptor)
    }
    this.axios.interceptors.response.use(
      x => x,
      this.unauthorizedResponseInterceptor
    )
    // @ts-ignore
    this.axios.interceptors.request.use(this.queueRequestInterceptor)
  }

  private queueRequestInterceptor = (config: AxiosRequestConfig) => {
    if (
      this.queueEnabled &&
      config.headers!.Authorization &&
      !this.isTokenValid(config.headers!.Authorization as string)
    ) {
      return new Promise(resolve => {
        this.rQueue.push({
          config: config,
          resolve: () => {
            config.headers = {
              ...config.headers,
              Authorization: `Bearer ${this.getToken()}`
            }
            return resolve(config)
          }
        })
        this.triggerTokenRefresh()
      })
    }
    return config
  }

  private prepareUrl = (url: string, config: RequestConfig) => {
    if (!config.rawUrl) {
      if (url.startsWith('/')) url = url.substr(1, url.length - 1)
      if (this.useRestPrefix) {
        return `/${this.prefix}/${this.version}/${this.name}/rest/${config.aud}/${config.authentication}/${url}`
      } else {
        return `/${this.prefix}/${this.version}/${this.name}/${config.aud}/${config.authentication}/${url}`
      }
    } else {
      return url
    }
  }

  private unauthorizedResponseInterceptor = async (
    error: any
  ): Promise<any> => {
    return this.onResponseError(error)
  }

  private debugRequestInterceptor = (request: any): Promise<any> => {
    console.log(
      `[${this.name}] [${request.method}] Starting Request`,
      request.baseURL,
      request.url
    )
    return request
  }
  private debugResponseInterceptor = (response: any): Promise<any> => {
    console.log(`[${this.name}] [${response.status}]`, response.config.url)
    return response
  }
  private verboseRequestInterceptor = (request: any): Promise<any> => {
    console.info(
      `[${this.name}] Starting Request ${request.baseURL}${request.url}`
    )
    return request
  }
  private verboseResponseInterceptor = (response: any): Promise<any> => {
    console.info(`[${this.name}] Returning Response ${response.status}`)
    return response
  }

  private requestTimingInterceptor = (request: any): Promise<any> => {
    request.start_at = new Date().valueOf()
    request.uuid = uuid()
    return request
  }

  private responseTimingInterceptor = (response: any): Promise<any> => {
    const currentDate: number = new Date().valueOf()
    const diff: number = currentDate - response.config.start_at
    const logInformation: any = {
      sessionId: this.sessionId,
      tenant: this.getTenantKey(),
      ssr: typeof window === 'undefined', // quick check if in node
      service: this.name,
      method: response.config.method,
      status: response.status,
      timing: diff,
      timestamp: currentDate,
      url: response.config.url,
      query: response.config.query
    }
    console.log(JSON.stringify(logInformation))
    return response
  }

  private extractParameters = (
    options: RequestConfig
  ): ServiceRequestConfig => {
    const {
      authentication,
      aud,
      injectToken,
      rawUrl,
      injectTenant,
      cache
    }: RequestConfig = options
    return {
      rawUrl: rawUrl,
      authentication: authentication ? authentication : Authentication.PUBLIC,
      injectToken: typeof injectToken !== 'undefined' ? injectToken : true,
      injectTenant: typeof injectTenant !== 'undefined' ? injectTenant : true,
      aud: aud ? aud : Audience.APP,
      cache: typeof cache !== 'undefined' ? cache : false
    }
  }

  private setTenantHeader = (
    options: RequestConfig,
    config: ServiceRequestConfig
  ) => {
    if (config.injectTenant) {
      options.headers = {
        ...options.headers,
        [ONE_TENANT_HEADER_NAME]: this.getTenantKey()
      }
    }
  }

  private setTokenHeader = (
    options: RequestConfig,
    config: ServiceRequestConfig
  ) => {
    if (this.shouldUseToken(config)) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${this.getToken()}`
      }
    }
  }

  private shouldUseToken(config: ServiceRequestConfig): boolean {
    return (
      !!this.getToken() &&
      config.authentication !== Authentication.PUBLIC &&
      config.injectToken
    )
  }

  public resendRequest(request: AxiosRequestConfig) {
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${this.getToken()}`
    }
    return this.axios.request(request)
  }

  get = <T = any>(
    url: string,
    options = { ...this.defaultRequestOptions }
  ): AxiosPromise<T> => {
    const config: ServiceRequestConfig = this.extractParameters(options)
    const parsedUrl = this.prepareUrl(url, config)
    if (config.cache && this.cacheEnabled) {
      const hash = this.cache.hash(parsedUrl, options)
      if (this.cache.has(hash)) {
        if (!this.cache.isExpired(hash)) {
          return new Promise(resolve =>
            resolve(cloneDeep(this.cache.get(hash)!.response))
          )
        }
      }
      this.setTenantHeader(options, config)
      this.setTokenHeader(options, config)
      return new Promise((resolve, reject) => {
        this.axios
          .get(parsedUrl, options)
          .then((response: AxiosResponse<T>) => {
            if (typeof config.cache === 'boolean') {
              this.cache.put(hash, [parsedUrl], response)
            } else {
              this.cache.put(hash, config.cache!.tags, response)
            }
            return resolve(response)
          })
          .catch(err => reject(err))
      })
    }
    this.setTenantHeader(options, config)
    this.setTokenHeader(options, config)
    return this.axios.get(parsedUrl, options)
  }

  post = <T = any>(
    url: string,
    data?: any,
    options = { ...this.defaultRequestOptions }
  ): AxiosPromise<T> => {
    const config: ServiceRequestConfig = this.extractParameters(options)
    const parsedUrl = this.prepareUrl(url, config)
    this.setTenantHeader(options, config)
    this.setTokenHeader(options, config)
    return this.axios.post(parsedUrl, data, options)
  }

  patch = <T = any>(
    url: string,
    data?: any,
    options = { ...this.defaultRequestOptions }
  ): AxiosPromise<T> => {
    const config: ServiceRequestConfig = this.extractParameters(options)
    const parsedUrl = this.prepareUrl(url, config)
    this.setTenantHeader(options, config)
    this.setTokenHeader(options, config)
    return this.axios.patch(parsedUrl, data, options)
  }

  put = <T = any>(
    url: string,
    data?: any,
    options = { ...this.defaultRequestOptions }
  ): AxiosPromise<T> => {
    const config: ServiceRequestConfig = this.extractParameters(options)
    const parsedUrl = this.prepareUrl(url, config)
    this.setTenantHeader(options, config)
    this.setTokenHeader(options, config)
    return this.axios.put(parsedUrl, data, options)
  }

  delete = <T = any>(
    url: string,
    options = { ...this.defaultRequestOptions }
  ): AxiosPromise<T> => {
    const config: ServiceRequestConfig = this.extractParameters(options)
    const parsedUrl = this.prepareUrl(url, config)
    this.setTenantHeader(options, config)
    this.setTokenHeader(options, config)
    // @ts-ignore
    return this.axios.delete(parsedUrl, { ...options })
  }
}

export class ApiServiceWithSettings extends ApiService {
  getListSettings(): AxiosPromise<Array<CMS.Responses.SettingDto>> {
    return this.get('/settings', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getSettings(key: string): AxiosPromise<CMS.Responses.SettingDto> {
    return this.get(`/settings/${key}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  setSettings(key: string, body: any): AxiosPromise<void> {
    return this.put(`/settings/${key}`, body, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      headers: {
        'content-type': 'application/json'
      }
    })
  }
}

export interface ApiNamespaces {
  app: ApiService
  backoffice: ApiServiceWithSettings
  invalidateAll(): void
}
