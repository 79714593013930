import { AxiosResponse } from 'axios'
import { cloneDeep } from 'lodash-es'
import { RequestConfig } from './common'
import hash from 'object-hash'

export interface CachePolicy {
  ttl?: number
  tags: Array<string>
}

export interface TTLCache {
  timestamp: number
  response: AxiosResponse
  tags: Array<string>
}

export class APICache {
  defaultTTL: number = 100000
  cachedRequests: Map<string, TTLCache> = new Map()

  has = (key: string) => this.cachedRequests.has(key)
  get = (key: string): TTLCache | null => this.cachedRequests.get(key) || null
  put = (
    key: string,
    tags: Array<string>,
    response: AxiosResponse,
    ttl: number = this.defaultTTL
  ) => {
    const res = {
      timestamp: new Date().valueOf() + ttl,
      tags,
      response
    }
    this.cachedRequests.set(key, cloneDeep(res))
  }

  hash = (url: string, options: RequestConfig) =>
    hash({
      url,
      params: options.params,
      data: options.data
    })

  isExpired = (key: string): boolean =>
    this.has(key) &&
    this.cachedRequests.get(key)!.timestamp < new Date().valueOf()
  invalidate = (tags: Array<string> | string) => {
    this.cachedRequests.forEach((value: TTLCache, key: string) => {
      if (Array.isArray(tags)) {
        const intersection = value.tags.filter(
          (tag: string) => tags.indexOf(tag) > -1
        )
        if (intersection.length > 0) {
          this.cachedRequests.delete(key)
        }
      } else {
        if (value.tags.indexOf(tags) > -1) {
          this.cachedRequests.delete(key)
        }
      }
    })
  }
  invalidateAll = () => this.cachedRequests.clear()
}
