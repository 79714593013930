import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { BackofficeTasksApi } from './backoffice'
import { AppTasksApi } from './app'
import { Queue } from '../../queue'

export class Tasks implements ApiNamespaces {
  app: AppTasksApi
  backoffice: BackofficeTasksApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new AppTasksApi(name, sessionId, requestsQueue, options)

    this.backoffice = new BackofficeTasksApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
