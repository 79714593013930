import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { Queue } from '../../queue'
import { BackofficeCommentsApi } from './backoffice'
import { AppCommentsApi } from './app'

export class Comments implements ApiNamespaces {
  app: AppCommentsApi
  backoffice: BackofficeCommentsApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new AppCommentsApi(name, sessionId, requestsQueue, options)

    this.backoffice = new BackofficeCommentsApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
