import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { AppOrderpathApi } from './app'
import { BackofficeOrderpathApi } from './backoffice'
import { Queue } from '../../queue'

export class Orderpath implements ApiNamespaces {
  app: AppOrderpathApi
  backoffice: BackofficeOrderpathApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new AppOrderpathApi(name, sessionId, requestsQueue, options)
    this.backoffice = new BackofficeOrderpathApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
