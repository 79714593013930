import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Tenants } from '@one/types'

export class BackofficeTenantsApi extends ApiServiceWithSettings {
  getCurrentTenant(): AxiosPromise<Tenants.Tenant> {
    return this.get('/tenants/self', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
