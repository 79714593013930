import {
  Version,
  OneAPIOptions,
  ApiInstances,
  Authentication,
  Audience,
  IQueuedRequest
} from './common'
import uuid from 'uuid-random'
import { Account } from './services/account'
import { Analytics } from './services/analytics'
import { Orderpath } from './services/orderpath'
import { Orders } from './services/orders'
import { Stocks } from './services/stocks'
import { Catalog } from './services/catalog'
import { Auth } from './services/auth'
import { Pricing } from './services/pricing'
import { Cms } from './services/cms'
import { Comments } from './services/comments'
import { Invoices } from './services/invoices'
import { GoodsIssuedNotes } from './services/goodsIssuedNotes'
import { Rma } from './services/rma'
import { Notif } from './services/notif'
import { Products } from './services/products'
import { Tenants } from './services/tenants'
import { Plugins } from './services/plugins'
import { AxiosPromise } from 'axios'
import { CMS } from '@one/types'
import { Queue } from './queue'
import { Permissions } from './services/permissions'
import { Payments } from './services/payments'
import { DocumentNumbers } from './services/documentNumbers'
import { Branches } from './services/branches'
import { Prints } from './services/prints'
import { Tasks } from './services/tasks'
import { Webhooks } from './services/webhooks'
import { Socks } from './services/socks'
import { Pricehelper } from './services/pricehelper'
import { Translations } from './services/translations'

interface Apis {
  account: Account
  analytics: Analytics
  orderpath: Orderpath
  orders: Orders
  stock: Stocks
  catalog: Catalog
  authserver: Auth
  pricing: Pricing
  cms: Cms
  comments: Comments
  invoices: Invoices
  goodsIssuedNotes: GoodsIssuedNotes
  rma: Rma
  product: Products
  notif: Notif
  tenants: Tenants
  permissions: Permissions
  payments: Payments
  plugins: Plugins
  prints: Prints
  tasks: Tasks
  webhooks: Webhooks
  documentNumbers: DocumentNumbers
  branches: Branches
  socks: Socks
  translations: Translations
  pricehelper: Pricehelper

  flushQueue(): void
  invalidateAllInstances(): void
  getListSettings(
    service: string
  ): AxiosPromise<Array<CMS.Responses.SettingDto>>
  getSettings(
    service: string,
    key: string
  ): AxiosPromise<CMS.Responses.SettingDto>
  setSettings(service: string, key: string, body: any): AxiosPromise<void>
}

export default {
  init: (options: OneAPIOptions): Apis => {
    const sessionId: string = uuid()
    const requestsQueue: Queue<IQueuedRequest> = new Queue()
    const instances: ApiInstances = {
      account: new Account('account', sessionId, requestsQueue, options),
      analytics: new Analytics('analytics', sessionId, requestsQueue, options),
      orderpath: new Orderpath('orderpath', sessionId, requestsQueue, options),
      orders: new Orders('orders', sessionId, requestsQueue, options),
      stock: new Stocks('stock', sessionId, requestsQueue, options),
      catalog: new Catalog('catalog', sessionId, requestsQueue, options),
      authserver: new Auth('authserver', sessionId, requestsQueue, options),
      pricing: new Pricing('pricing', sessionId, requestsQueue, options),
      pricehelper: new Pricehelper(
        'pricehelper',
        sessionId,
        requestsQueue,
        options
      ),
      cms: new Cms('cms', sessionId, requestsQueue, options),
      comments: new Comments('comments', sessionId, requestsQueue, options),
      invoices: new Invoices('invoices', sessionId, requestsQueue, options),
      goodsIssuedNotes: new GoodsIssuedNotes(
        'goods-issued-notes',
        sessionId,
        requestsQueue,
        options
      ),
      rma: new Rma('rma', sessionId, requestsQueue, options),
      product: new Products('product', sessionId, requestsQueue, options),
      notif: new Notif('notif', sessionId, requestsQueue, options),
      tenants: new Tenants('tenants', sessionId, requestsQueue, options),
      plugins: new Plugins('plugins', sessionId, requestsQueue, options),
      permissions: new Permissions(
        'permissions',
        sessionId,
        requestsQueue,
        options
      ),
      payments: new Payments('payments', sessionId, requestsQueue, options),
      prints: new Prints('prints', sessionId, requestsQueue, options),
      tasks: new Tasks('tasks', sessionId, requestsQueue, options),
      webhooks: new Webhooks('webhooks', sessionId, requestsQueue, options),
      socks: new Socks('socks', sessionId, requestsQueue, {
        useRestPrefix: true,
        ...options
      }),
      documentNumbers: new DocumentNumbers(
        'documentnumbers',
        sessionId,
        requestsQueue,
        options
      ),
      branches: new Branches('branches', sessionId, requestsQueue, options),
      translations: new Translations(
        'translations',
        sessionId,
        requestsQueue,
        options
      )
    }
    return {
      ...instances,
      flushQueue() {
        while (requestsQueue.size()) {
          const request: IQueuedRequest | undefined = requestsQueue.pop()
          if (request) {
            request.resolve()
          }
        }
      },
      invalidateAllInstances: () => {
        Object.keys(instances).map(instance =>
          // @ts-ignore
          instances[instance].invalidateAll()
        )
      },
      getListSettings(
        name: string
      ): AxiosPromise<Array<CMS.Responses.SettingDto>> {
        if (!instances[name]) {
          throw new Error(
            `[oneAPI] No instance named ${name} in getListSettings`
          )
        }
        return instances[name].backoffice.get('/settings', {
          authentication: Authentication.REQUIRED,
          aud: Audience.BACKOFFICE
        })
      },
      getSettings(
        name: string,
        key: string
      ): AxiosPromise<CMS.Responses.SettingDto> {
        if (!instances[name]) {
          throw new Error(`[oneAPI] No instance named ${name} in getSettings`)
        }
        return instances[name].backoffice.get(`/settings/${key}`, {
          authentication: Authentication.REQUIRED,
          aud: Audience.BACKOFFICE
        })
      },
      setSettings(name: string, key: string, body: any): AxiosPromise<void> {
        if (!instances[name]) {
          throw new Error(`[oneAPI] No instance named ${name} in setSettings`)
        }
        return instances[name].backoffice.put(`/settings/${key}`, body, {
          authentication: Authentication.REQUIRED,
          aud: Audience.BACKOFFICE,
          headers: {
            'content-type': 'application/json'
          }
        })
      }
    } as Apis
  }
}
export { Version, Apis }
