import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'

export class BackofficePrintsApi extends ApiServiceWithSettings {
  updateTemplateContent(templateId: number, body: any): AxiosPromise<any> {
    return this.put(`/templates/${templateId}`, body, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateTemplate(templateId: number, body: any): AxiosPromise<any> {
    return this.patch(`/templates/${templateId}`, body, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  publishTemplate(version: number): AxiosPromise<any> {
    return this.post(
      `/templates/versions/${version}/publish`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  getTemplateById(templateId: number): AxiosPromise {
    return this.get(`/templates/${templateId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getTemplates(): AxiosPromise<any> {
    return this.get('/templates', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getTemplatesByType(type: number): AxiosPromise<any> {
    return this.get(`/template-types/${type}/templates`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getTemplateVersions(templateId: number): AxiosPromise<any> {
    return this.get(`/templates/${templateId}/versions`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getTemplateTypes(): AxiosPromise<any> {
    return this.get('/template-types', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getTemplateTypeById(templateTypeId: number): AxiosPromise<any> {
    return this.get(`/template-types/${templateTypeId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteTemplateType(templateTypeId: number): AxiosPromise<any> {
    return this.delete(`/template-types/${templateTypeId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createTemplateType(templateType: {
    name: string
    description: string
    service: string
    fields: any
  }): AxiosPromise<any> {
    return this.post('/template-types', templateType, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createTemplate(template: {
    description: string
    typeId: number
    content: string
  }): AxiosPromise<any> {
    return this.post('/templates', template, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
