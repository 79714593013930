import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { DocumentNumbers } from '@one/types'

import DocumentTypeEnum = DocumentNumbers.Backoffice.DocumentTypeEnum
import DocumentNumbersResponses = DocumentNumbers.Backoffice.Responses

export class BackofficeDocumentNumbersApi extends ApiServiceWithSettings {
  getDocumentNumberTemplate(
    documentType: DocumentTypeEnum
  ): AxiosPromise<DocumentNumbersResponses.TemplateRestView> {
    return this.get(`document-number-template/${documentType}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  setNewDocumentNumberTemplate(
    documentType: DocumentTypeEnum,
    template: string
  ): AxiosPromise<void> {
    return this.put(
      `document-number-template/${documentType}`,
      {},
      {
        params: {
          template
        },
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  getVariableKeys(
    documentType: DocumentTypeEnum
  ): AxiosPromise<DocumentNumbersResponses.VariableRestViewMap> {
    return this.get(`document-number-template/${documentType}/variable-keys`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getAvailableDocumentTypes(): AxiosPromise<DocumentTypeEnum> {
    return this.get('document-number-template/document-types', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
