// source: https://dev.to/glebirovich/typescript-data-structures-stack-and-queue-hld
interface IQueue<T> {
  push(item: T): void
  pop(): T | undefined
  size(): number
}

abstract class Collection<T> {
  protected storage: T[] = []

  size(): number {
    return this.storage.length
  }
  abstract isFull(): boolean
}

export class Queue<T> extends Collection<T> implements IQueue<T> {
  constructor(private capacity: number = Infinity) {
    super()
  }

  push(item: T): void {
    if (this.isFull()) {
      throw Error('Queue has reached max capacity, you cannot add more items')
    }
    // In the derived class, we can access protected properties of the abstract class
    this.storage.push(item)
  }

  pop(): T | undefined {
    return this.storage.shift()
  }

  // Implementation of the abstract method
  isFull(): boolean {
    return this.capacity === this.size()
  }
}
