import { ApiService, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Products } from '@one/types'
import qs from 'qs'

export class AppProductsApi extends ApiService {
  getManufacturers(
    sku: string
  ): AxiosPromise<Array<Products.CableDrum.Responses.Manufacturer>> {
    return this.get(`/products/${sku}/manufacturers`, {
      authentication: Authentication.OPTIONAL,
      cache: {
        tags: ['cable-drum-manufacturers']
      }
    })
  }
  getStocksByWarehouse(
    sku: string,
    warehouseIds: Array<string>
  ): AxiosPromise<Products.CableDrum.Responses.ProductStocks> {
    return this.get('/stocks-by-warehouse', {
      params: {
        sku,
        warehouseIds
      },
      authentication: Authentication.OPTIONAL,
      cache: {
        tags: ['cable-drum-stocks']
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }
  addStocksToSummary(
    command: Products.CableDrum.Request.CalculateCableProductsSummaryCommand
  ): AxiosPromise<Products.CableDrum.Responses.CableProductsSummary> {
    return this.post('/products/cables/summary', command, {
      authentication: Authentication.OPTIONAL
    })
  }
}
