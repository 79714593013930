import Products from '@one/types'
import Big from 'big.js'
import { format } from 'date-fns'
/*
measurementUnits = {
  contentUnitCode: "C62"
  contentUnitDescription: "One unit"
  orderUnitCode: "C62"
  orderUnitDescription: "One unit"
  packingQuantity: 1 -> to jest używane do przeliczenia jako 'converter'
  priceQuantity: 1
  quantityInterval: 1
  quantityMin: 1
}

Przykład:
Jednostką podstawową jest sztuka.
Jednostką sprzedażową jest karton.
W kartonie mamy 10 sztuk (packingQuantity = 10).
Użytkownik na FE kupuje w kartonach.
Z stock service przychodzi contentUnit = 1000
FE powinien wyświetlić, że na magazynie jest contentUnit/packingQuantity czyli 1000/10 = 100 kartonów.
Klient dodając do koszyka dodaje 1 karton.

 */

export const toOrderUnit = (contentUnit: number, converter: number): string => {
  if (!isNaN(contentUnit)) {
    return Big(contentUnit)
      .div(Big(converter))
      .toString()
  }
  console.warn(`${contentUnit} is NaN`)
  return contentUnit.toFixed(0)
}

/*
  Logic of this function is the same as in toOrderUnit,
  but we use toPackagingQuantityPrice to calculate price
 */
export const toPackagingQuantityPrice = toOrderUnit

export const toContentUnit = (orderUnit: number, converter: number) => {
  if (!isNaN(orderUnit)) {
    return orderUnit * converter
  }
  console.warn(`${orderUnit} is NaN`)
  return orderUnit
}

export const toOrderPrice = (price: number, converter: number) => {
  if (!isNaN(price)) {
    return price * converter
  }
  console.warn(`${price} is NaN`)
  return price
}

export const priceFormatter = (
  price: number,
  currency: Products.Currency = {
    iso: 'pl-PL',
    code: 'PLN',
    symbol: 'zł',
  }
) => {
  if (!isNaN(price) && price !== null) {
    return replaceNbsps(
      price.toLocaleString(currency.iso, {
        style: 'currency',
        currency: currency.code,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...currency,
      })
    )
  } else {
    console.warn(`${price} is NaN`)
  }
}

export const replaceNbsps = (str: string) => {
  const re = new RegExp(String.fromCharCode(160), 'g')
  return str.replace(re, ' ')
}

export const prepareFormatDate = (date: Date) =>
  date ? format(date, 'yyyy-MM-dd') : null
