import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { Queue } from '../../queue'
import { AppSocksApi } from './app'
import { BackofficeSocksApi } from './backoffice'

export class Socks implements ApiNamespaces {
  app: AppSocksApi
  backoffice: BackofficeSocksApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new AppSocksApi(name, sessionId, requestsQueue, options)
    this.backoffice = new BackofficeSocksApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
