import { ApiService, Authentication } from '../../common'
import { RMA } from '@one/types'
import { AxiosPromise } from 'axios'

export class AppRmaApi extends ApiService {
  createComplaint(form: RMA.App.Request.ComplaintFormDto): AxiosPromise<void> {
    this.invalidate(['rma-list', 'rma'])
    return this.put('/complaint', form, {
      authentication: Authentication.REQUIRED
    })
  }

  getComplaintReasons(): AxiosPromise<
    Array<RMA.App.Response.ComplaintReasonDto>
  > {
    return this.get('/complaint-reason', {
      authentication: Authentication.REQUIRED,
      cache: {
        ttl: 60 * 100,
        tags: ['rma']
      }
    })
  }

  getComplaints(
    offset: number,
    rows: number
  ): AxiosPromise<RMA.App.Response.PaginationComplaintDto> {
    return this.get('/complaints', {
      params: {
        offset,
        rows
      },
      authentication: Authentication.REQUIRED,
      cache: {
        ttl: 60 * 100,
        tags: ['rma-list']
      }
    })
  }

  getComplaint(
    complaintId: string
  ): AxiosPromise<RMA.App.Response.ComplaintDto> {
    return this.get(`/complaint/${complaintId}`, {
      authentication: Authentication.REQUIRED,
      cache: {
        ttl: 60 * 100,
        tags: ['rma-single']
      }
    })
  }
}
