import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { AppAuthApi } from './app'
import { BackofficeAuthApi } from './backoffice'
import { Queue } from '../../queue'

export class Auth implements ApiNamespaces {
  app: AppAuthApi
  backoffice: BackofficeAuthApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new AppAuthApi(name, sessionId, requestsQueue, options)
    this.backoffice = new BackofficeAuthApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
