import { ApiService, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Orders } from '@one/types'
import qs from 'qs'

export class AppOrdersApi extends ApiService {
  getOrdersList(
    start: number,
    rows: number,
    search: string | null,
    startDate: string | null,
    endDate: string | null,
    shipmentStatuses: Array<string> | null
  ): AxiosPromise<Orders.Responses.OrderPagination> {
    return this.get('/order', {
      params: {
        start: start,
        rows: rows,
        search: search || null,
        startDate: startDate ? startDate : null,
        endDate: endDate ? endDate : null,
        shipmentStatuses: shipmentStatuses ? shipmentStatuses : null
      },
      authentication: Authentication.REQUIRED,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }

  getDetailOrder(orderId: string): AxiosPromise<Orders.DetailedOrder.Order> {
    return this.get(`/order/${orderId}`, {
      authentication: Authentication.REQUIRED
    })
  }

  getOrderStatuses(
    startDate: string | null,
    endDate: string | null,
    search: string | null
  ): AxiosPromise<Array<Orders.OrderStatusDto>> {
    return this.get('/order-status', {
      params: {
        startDate: startDate || null,
        endDate: endDate || null,
        search: search || null
      },
      authentication: Authentication.REQUIRED,
      cache: {
        tags: ['order-status']
      }
    })
  }

  payForOrder(
    orderId: string,
    callbackUrl: string
  ): AxiosPromise<Orders.Responses.PayForOrderResult> {
    return this.post(
      `/order/${orderId}/pay`,
      {
        callbackUrl
      },
      {
        authentication: Authentication.REQUIRED
      }
    )
  }

  exportOnlineOrder(orderId: string): AxiosPromise<BlobPart> {
    return this.get(`/order/${orderId}/export`, {
      authentication: Authentication.REQUIRED,
      responseType: 'blob'
    })
  }
}
