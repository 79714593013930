import { ApiService } from '../../common'
import { AxiosPromise } from 'axios'
import qs from 'qs'
import { Stocks } from '@one/types'
import ProductsStocks = Stocks.Responses.ProductsStocks
import Warehouse = Stocks.Warehouse
import ProductStocks = Stocks.Responses.ProductStocks

export class AppStocksApi extends ApiService {
  getStocksForProductsAndWarehouses(
    products: Array<string>,
    warehouses: Array<string>
  ): AxiosPromise<ProductsStocks> {
    return this.get('/products/stocks', {
      params: {
        skus: products && products.length > 0 ? products : undefined,
        warehouses: warehouses && warehouses.length > 0 ? warehouses : undefined
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }

  getStocksForProduct(
    productId: string,
    warehouses?: Array<string>
  ): AxiosPromise<ProductStocks> {
    if (warehouses) {
      return this.get('/products/stocks-for-one-product', {
        params: {
          sku: productId,
          warehouses
        },
        paramsSerializer: function(params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        },
        cache: {
          tags: ['stocks', 'product']
        }
      })
    }
    return this.get('/products/stocks-for-one-product', {
      params: {
        sku: productId
      },
      cache: {
        tags: ['stocks', 'product']
      }
    })
  }

  getWarehouses(): AxiosPromise<Array<Warehouse>> {
    return this.get('/warehouses', {
      cache: {
        tags: ['warehouses']
      }
    })
  }

  getWarehouse(warehouseId: string): AxiosPromise<Warehouse> {
    return this.get(`/warehouses/${warehouseId}`, {
      cache: {
        tags: ['warehouse']
      }
    })
  }
}
