import { ApiService, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Plugins } from '@one/types'
import ActivePlugin = Plugins.ActivePlugin
import TokenResponse = Plugins.TokenResponse

export class AppPluginsApi extends ApiService {
  getPlugins(): AxiosPromise<Array<ActivePlugin>> {
    return this.get('/active-plugins', {
      authentication: Authentication.PUBLIC,
      aud: Audience.APP
    })
  }

  createToken(pluginId: string): AxiosPromise<TokenResponse> {
    return this.post(
      '/create-token',
      {},
      {
        authentication: Authentication.OPTIONAL,
        aud: Audience.APP,
        params: {
          pluginId
        }
      }
    )
  }

  generateAuthWithOneToken(pluginId: string): AxiosPromise<TokenResponse> {
    return this.post(
      `/auth-with-one/${pluginId}/generateToken`,
      {},
      {
        authentication: Authentication.OPTIONAL,
        aud: Audience.APP
      }
    )
  }
}
