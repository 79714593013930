import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Analytics } from '@one/types'

export class AnalyticsBackofficeApi extends ApiServiceWithSettings {
  getOrderValuesPlots(
    start: string,
    end: string,
    clientId: string
  ): AxiosPromise<Analytics.Backoffice.Response.Plot> {
    return this.get(`/plot/order-values`, {
      params: {
        start,
        end,
        clientId: clientId || null
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
