import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'

import { Security } from '@one/types'

import SecurityRequests = Security.Backoffice.Requests.SecurityRequests
import SecurityResponse = Security.Backoffice.Responses.SecurityResponse
import DescriptorMap = Security.Backoffice.Responses.DescriptorMap

export class BackofficePermissionsApi extends ApiServiceWithSettings {
  checkSecurity(body: SecurityRequests): AxiosPromise<Array<SecurityResponse>> {
    return this.post(`/security/check-permit`, body, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  fetchDescriptors(): AxiosPromise<DescriptorMap> {
    return this.get(`/security/descriptors`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
