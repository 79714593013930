import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'

export class BackofficeTranslationsApi extends ApiServiceWithSettings {
  defaults(): AxiosPromise<string> {
    return this.get('/translations/defaults/export', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  status(): AxiosPromise<string> {
    return this.get('/translations/defaults/status', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  export(): AxiosPromise<string> {
    return this.get('/translations/export', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  import(file: FormData): AxiosPromise<string> {
    return this.put('/translations/import', file, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      headers: {
        'Content-Type': 'text/csv'
      }
    })
  }
  remove(): AxiosPromise<string> {
    return this.delete('/translations', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
