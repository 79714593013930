import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Orders, Pagination } from '@one/types'
import qs from 'qs'
import { pickBy } from 'lodash-es'

export class BackofficeOrdersApi extends ApiServiceWithSettings {
  getOrders(
    pageNumber: number,
    pageSize: number,
    clientId: string | null,
    search: string | null,
    shipmentStatuses: Array<string> | null,
    startDate: string | null,
    endDate: string | null,
    byOperatorId: string | null,
    paymentMethodId: string | null,
    orderChannel: string | null,
    branchId: string | null
  ): AxiosPromise<Pagination.Pager<Orders.Backoffice.BackofficeOrder>> {
    return this.get('/order', {
      params: pickBy({
        pageNumber,
        pageSize,
        clientId,
        search,
        shipmentStatuses,
        startDate,
        endDate,
        byOperatorId,
        paymentMethodId,
        orderChannel,
        branchId
      }),
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getOrderShipmentStatuses(): AxiosPromise<Array<Orders.OrderStatusDto>> {
    return this.get('/order-status', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        tags: ['order-status']
      }
    })
  }

  getOrderDetail(orderId: string): AxiosPromise<Orders.DetailedOrder.Order> {
    return this.get(`/order/${orderId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getOrderDetailByOrderNumber(
    orderNumber: string
  ): AxiosPromise<Orders.DetailedOrder.Order> {
    return this.get(`/orders/by-order-number`, {
      params: pickBy({
        orderNumber
      }),
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getOrderDetailBySalesOrderNumber(
    salesOrderNumber: string
  ): AxiosPromise<Orders.DetailedOrder.Order> {
    return this.get(`/orders/by-sales-order-number`, {
      params: pickBy({
        salesOrderNumber
      }),
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getShipmentsStatus(): AxiosPromise<
    Array<Orders.Backoffice.Response.ShipmentStatus>
  > {
    return this.get('/shipment-status', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['shipmentStatus']
      }
    })
  }

  createShipmentStatus(
    status: Orders.Backoffice.Request.ShipmentStatusRequest
  ): AxiosPromise<void> {
    this.invalidate(['shipmentStatus'])
    return this.post('/shipment-status', status, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteShipmentStatus(shipmentStatusId: string): AxiosPromise<void> {
    this.invalidate(['shipmentStatus'])
    return this.delete(`/shipment-status/${shipmentStatusId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateShipmentStatus(
    shipmentStatusId: string,
    status: Orders.Backoffice.Request.ShipmentStatusRequest
  ): AxiosPromise<void> {
    this.invalidate(['shipmentStatus'])
    return this.patch(`/shipment-status/${shipmentStatusId}`, status, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  changeProductLineQuantity(
    salesOrderNumber: string,
    changeQuantity: Orders.Backoffice.Request.ChangeQuantity
  ): AxiosPromise<Object> {
    return this.put('/sales-orders/product-line-quantity', changeQuantity, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      params: {
        salesOrderNumber
      }
    })
  }

  moveQuantity(
    salesOrderNumber: string,
    moveQuantity: Orders.Backoffice.Request.MoveQuantity
  ): AxiosPromise<Orders.Backoffice.Response.MoveQuantity> {
    return this.post('/sales-orders/move-quantity', moveQuantity, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      params: {
        salesOrderNumber
      }
    })
  }

  cancelSaleOrder(salesOrderNumber: string): AxiosPromise<Object> {
    return this.post(
      '/sales-orders/cancel',
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE,
        params: {
          salesOrderNumber
        }
      }
    )
  }

  getAllSalesOrders(
    parameters: Orders.Backoffice.Request.SalesOrderRequestParameters
  ): AxiosPromise<
    Pagination.Pager<Orders.Backoffice.Response.SalesOrderListItem>
  > {
    return this.get('/sales-orders', {
      params: {
        pageNumber: parameters.pageNumber,
        pageSize: parameters.pageSize,
        clientId: parameters.clientId,
        search: parameters.search,
        creationStartDate: parameters.creationStartDate,
        creationEndDate: parameters.creationEndDate,
        deliveryStartDate: parameters.deliveryStartDate,
        deliveryEndDate: parameters.deliveryEndDate,
        deliveryMethodIds: parameters.deliveryMethodIds,
        statuses: parameters.statuses,
        warehouseIds: parameters.warehouseIds,
        branchId: parameters.branchId
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  exportSingleOfflineOrder(offlineOrderId: string): AxiosPromise<string> {
    return this.get(`/offline-order/single/export`, {
      params: {
        offlineOrderId
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      responseType: 'blob'
    })
  }

  exportOrderToXlsx(orderId: string): AxiosPromise<string> {
    return this.get(`/order/${orderId}/export`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      responseType: 'blob'
    })
  }
}
