import { ApiService, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { CartOpenedAppRestCommand, OfferOpenedAppRestCommand, ClientLoggedAppRestCommand } from '@one/types'

export class AppWebhooksApi extends ApiService {
  publishCartOpened(payload: CartOpenedAppRestCommand): AxiosPromise<void> {
    return this.post('/webhooks/publish/cart_opened', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.APP
    })
  }

  publishClientLogged(payload: ClientLoggedAppRestCommand): AxiosPromise<void> {
    return this.post(
      '/webhooks/publish/client_logged',
      payload,
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.APP
      }
    )
  }

  publishOfferLogged(payload: OfferOpenedAppRestCommand): AxiosPromise<void> {
    return this.post('/webhooks/publish/offer_logged', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.APP
    })
  }
}
