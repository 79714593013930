import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { Queue } from '../../queue'
import { BackofficePermissionsApi } from './backoffice'
import { AppPermissionsApi } from './app'

export class Permissions implements ApiNamespaces {
  app: AppPermissionsApi
  backoffice: BackofficePermissionsApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new AppPermissionsApi(name, sessionId, requestsQueue, options)
    this.backoffice = new BackofficePermissionsApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
