// @ts-ignore
import Cookieparser from 'cookieparser'
import Cookies from 'js-cookie'
import { Base64 } from 'js-base64'

export const USER_CONFIGURATION = 'user_configuration'

export const getAllMethods = (obj: any) => {
  let props: Array<string> = []

  do {
    const l = Object.getOwnPropertyNames(obj)
      .concat(Object.getOwnPropertySymbols(obj).map((s) => s.toString()))
      .sort()
      .filter(
        (p, i, arr) =>
          typeof obj[p] === 'function' && // only the methods
          p !== 'constructor' && // not the constructor
          p !== 'state' &&
          (i === 0 || p !== arr[i - 1]) && // not overriding in this prototype
          !props.includes(p) // not overridden in a child
      )
    props = props.concat(l)
  } while (
    // eslint-disable-next-line no-param-reassign
    (obj = Object.getPrototypeOf(obj)) && // walk-up the prototype chain
    Object.getPrototypeOf(obj) // not the the Object prototype methods (hasOwnProperty, etc...)
  )

  return props
}
export const setUserConfiguration = (conf: any, res?: any) => {
  if (res) {
    // server side
    res.setHeader('Set-Cookie', [`user_configuration=${Base64.encode(conf)}`])
  } else {
    // client side
    Cookies.set(USER_CONFIGURATION, Base64.encode(conf))
  }
}
export const getUserConfiguration = (req?: any): string | null => {
  const config =
    req && req.headers && req.headers.cookie
      ? Cookieparser.parse(req.headers.cookie)[USER_CONFIGURATION]
      : Cookies.get(USER_CONFIGURATION)
  return config && Base64.decode(config)
}

export const getCookie = (cookieName: string, req?: any): string | null => {
  try {
    return req && req.headers && req.headers.cookie
      ? Cookieparser.parse(req.headers.cookie)[cookieName]
      : Cookies.get(cookieName)
  } catch (e) {
    return null
  }
}

export const extractFileNameFromContentDispositionHeader = (value: any) => {
  let responseFilename = /filename="([^;]*);?"/i.exec(value)
  if (responseFilename === null) {
    responseFilename = /filename=([^;]*);?/i.exec(value)
  }
  if (responseFilename !== null && responseFilename.length > 1) {
    return responseFilename[1]
  }
  return ''
}

export const exportResponseToBlobFile = (
  data: any,
  headers: any,
  overwriteFilename?: string
): void => {
  if (window) {
    const link = document.createElement('a')
    const url = window.URL.createObjectURL(new Blob([data]))
    const cDisposition = headers['content-disposition']
    const filename =
      overwriteFilename ||
      extractFileNameFromContentDispositionHeader(cDisposition)
    link.href = url
    link.download = filename
    if (link.download === '') {
      link.setAttribute('target', '_blank')
    }
    link.click()
  }
}

export const UrlWithSlash = (rawURL: string): string => {
  return rawURL.startsWith('/') ? rawURL : `/${rawURL}`
}

export const UrlWithoutSlash = (rawURL: string): string => {
  return rawURL.startsWith('/')
    ? rawURL
        .split('/')
        .filter((x) => x)
        .join('/')
    : rawURL
}
