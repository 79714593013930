import { throttle } from 'lodash-es'

export function Throttle(
  wait: number
): (
  target: any,
  prop: string
) => { enumerable: boolean; value: any; configurable: boolean } {
  return (target: any, prop: string) => {
    return {
      configurable: true,
      enumerable: false,
      value: throttle(target[prop], wait),
    }
  }
}
