import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { RMA } from '@one/types'

export class BackofficeRmaApi extends ApiServiceWithSettings {
  complaintReasonList(): AxiosPromise<
    Array<RMA.Backoffice.Response.ComplaintReasonDto>
  > {
    return this.get('/complaint-reason', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['rma-bo']
      }
    })
  }

  complaintReasonHandler(
    form: RMA.Backoffice.Request.ComplaintReasonFormDto
  ): AxiosPromise<void> {
    this.invalidate(['rma-bo'])
    return this.post('/complaint-reason', form, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteComplaintReason(complaintReasonId: string): AxiosPromise<void> {
    this.invalidate(['rma-bo'])
    return this.delete(`/complaint-reason/${complaintReasonId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
