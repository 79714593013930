import { ApiService, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { CMS, Pagination } from '@one/types'
import qs from 'qs'
import ListingBannersListDto = CMS.Responses.ListingBannersListDto
import ListingBannerDto = CMS.Responses.ListingBannerDto
import ArticleLink = CMS.Responses.ArticleLink
import ArticleTag = CMS.Articles.Tags.ArticleTag
import ArticleBody = CMS.Responses.ArticleBody
import GridSlot = CMS.Grid.GridSlot
import StaticPageDto = CMS.Responses.StaticPageDto

export class AppCmsApi extends ApiService {
  mainConfiguration(): AxiosPromise<CMS.Responses.MainConfigurationQuery> {
    return this.get('/configuration/main', {
      cache: {
        ttl: 60 * 100,
        tags: ['cms']
      },
      authentication: Authentication.PUBLIC
    })
  }

  localizedMainConfiguration(): AxiosPromise<CMS.Responses.MainCmsEntity> {
    return this.get('/localized_main_configuration', {
      cache: {
        ttl: 60 * 1000,
        tags: ['cms']
      },
      authentication: Authentication.PUBLIC
    })
  }

  getStaticPages(): AxiosPromise<CMS.Responses.PagesDto> {
    return this.get('/page', {
      cache: {
        ttl: 60 * 100,
        tags: ['cms']
      },
      authentication: Authentication.PUBLIC
    })
  }

  getPage(url: string): AxiosPromise<CMS.Responses.PageContentDto> {
    return this.get('/pageByUrl', {
      params: {
        url
      },
      cache: {
        ttl: 60 * 100,
        tags: ['cms']
      },
      authentication: Authentication.PUBLIC
    })
  }

  getBannersList(): AxiosPromise<ListingBannersListDto> {
    return this.get('/banners/', {
      cache: {
        ttl: 60 * 100,
        tags: ['cms']
      }
    })
  }

  getBanner(id: string): AxiosPromise<ListingBannerDto> {
    return this.get(`/banners/${id}`, {
      cache: {
        ttl: 60 * 100,
        tags: ['cms']
      }
    })
  }
  getArticles(
    pageNumber: number = 1,
    pageSize: number = 20,
    tags: Array<string> | null
  ): AxiosPromise<Pagination.Pager<ArticleLink>> {
    return this.get('/articles', {
      params: {
        pageNumber,
        pageSize,
        tags
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      cache: {
        tags: ['articles-list']
      },
      authentication: Authentication.OPTIONAL
    })
  }
  getArticleTags(): AxiosPromise<Array<ArticleTag>> {
    return this.get('/article-tags', {
      cache: {
        tags: ['articles-tags']
      },
      authentication: Authentication.OPTIONAL
    })
  }
  getArticle(slug: string): AxiosPromise<ArticleBody> {
    return this.get(`/articles/by-slug/${slug}`, {
      cache: {
        tags: ['article']
      },
      authentication: Authentication.OPTIONAL
    })
  }
  getGridBySlot(slotName: GridSlot): AxiosPromise<CMS.Grid.GridElement> {
    return this.get(`/grid/${slotName}`, {
      authentication: Authentication.OPTIONAL,
      cache: {
        tags: ['optional']
      }
    })
  }
  getStaticPageBySlug(slug: string): AxiosPromise<StaticPageDto> {
    return this.get('/static-page/', {
      authentication: Authentication.OPTIONAL,
      params: {
        slug
      },
      cache: {
        tags: ['optional']
      }
    })
  }
}
