import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { AnalyticsAppApi } from './app'
import { AnalyticsBackofficeApi } from './backoffice'
import { Queue } from '../../queue'

export class Analytics implements ApiNamespaces {
  app: AnalyticsAppApi
  backoffice: AnalyticsBackofficeApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new AnalyticsAppApi(name, sessionId, requestsQueue, options)
    this.backoffice = new AnalyticsBackofficeApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
