import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { GoodsIssuedNotes } from '@one/types'

export class BackofficeGoodsIssuedNotesApi extends ApiServiceWithSettings {
  getGoodsIssuedNotesList(
    pageNumber: number,
    pageSize: number,
    search: string | null,
    startDate: string | null,
    endDate: string | null,
    sortOrder: GoodsIssuedNotes.State.GoodsIssuedNoteSortingEnum,
    clientId: string | null,
    status: GoodsIssuedNotes.State.GoodsIssuedNoteStatusEnum,
    warehouseId: string | null,
    operatorId: string | null,
    invoiceFilter: GoodsIssuedNotes.State.GoodsIssuedNoteInvoiceFilterEnum,
    branchId: string | null
  ): AxiosPromise<
    GoodsIssuedNotes.Backoffice.Responses.GoodsIssuedNoteBackofficePager
  > {
    return this.get('/goods-issued-notes/search', {
      params: {
        pageNumber,
        pageSize,
        search: search || null,
        startDate: startDate || null,
        endDate: endDate || null,
        sortOrder,
        clientId: clientId || null,
        status,
        warehouseId: warehouseId || null,
        operatorId: operatorId || null,
        invoiceFilter: invoiceFilter || null,
        branchId: branchId || null
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getWarehouseDocumentsSuggestList(
    pageNumber: number,
    pageSize: number,
    query: string | null,
    clientId: string | null
  ): AxiosPromise<
    GoodsIssuedNotes.Backoffice.Responses.GoodsIssuedNoteBackofficePager
  > {
    return this.get('/warehouse-documents/suggest', {
      params: {
        pageNumber,
        pageSize,
        query: query || null,
        clientId: clientId || null
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  exportPdfGoodIssuedNotes(
    goodsIssuedNoteNumber: string
  ): AxiosPromise<BlobPart> {
    return this.get('/goods-issued-notes/export/pdf', {
      params: {
        goodsIssuedNoteNumber
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      responseType: 'blob'
    })
  }

  getGoodsIssuedNoteDetail(
    goodsIssuedNoteNumber: string
  ): AxiosPromise<GoodsIssuedNotes.Responses.GoodsIssuedNote> {
    return this.get('/goods-issued-notes', {
      params: {
        goodsIssuedNoteNumber
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createGoodsIssuedNote(
    data: GoodsIssuedNotes.Requests.CreateGoodsIssuedNoteRestCommand
  ) {
    return this.post('/goods-issued-notes', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getGoodsReceivedNotesList(
    pageNumber: number,
    pageSize: number,
    search: string | null,
    startDate: string | null,
    endDate: string | null,
    sortOrder: GoodsIssuedNotes.State.GoodsIssuedNoteSortingEnum,
    clientId: string | null,
    status: GoodsIssuedNotes.State.GoodsIssuedNoteStatusEnum,
    warehouseId: string | null,
    operatorId: string | null,
    invoiceFilter: GoodsIssuedNotes.State.GoodsIssuedNoteInvoiceFilterEnum,
    branchId: string | null
  ): AxiosPromise<
    GoodsIssuedNotes.Backoffice.Responses.GoodsReceivedNoteBackofficePager
  > {
    return this.get('/goods-received-notes/search', {
      params: {
        pageNumber,
        pageSize,
        search: search || null,
        startDate: startDate || null,
        endDate: endDate || null,
        sortOrder,
        clientId: clientId || null,
        status,
        warehouseId: warehouseId || null,
        operatorId: operatorId || null,
        invoiceFilter: invoiceFilter || null,
        branchId: branchId || null
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getGoodsReceivedNoteDetail(
    goodsReceivedNoteNumber: string
  ): AxiosPromise<GoodsIssuedNotes.Responses.GoodsIssuedNote> {
    return this.get('/goods-received-notes', {
      params: {
        goodsReceivedNoteNumber
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  confirmGoodsIssuedNote(
    goodsIssuedNoteNumber: string | number
  ): AxiosPromise<Object> {
    return this.put(
      '/goods-issued-notes/confirm',
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE,
        params: {
          goodsIssuedNoteNumber
        }
      }
    )
  }

  createGoodsReceivedNotes(
    data: GoodsIssuedNotes.Requests.CreateGoodsReceivedNoteRestCommand
  ): AxiosPromise<void> {
    this.invalidate(['goods-received-notes'])
    return this.post('/goods-received-notes', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  changeGoodsReceivedNoteNumber(
    lineNumber: string | number,
    quantity: string | number,
    goodsIssuedNoteNumber: string | number
  ): AxiosPromise<Object> {
    return this.put(
      '/goods-issued-notes/product-line-quantity',
      {
        lineNumber,
        quantity
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE,
        params: {
          goodsIssuedNoteNumber
        }
      }
    )
  }

  sumSelectedGoodsIssuedNotes(
    data: GoodsIssuedNotes.Requests.SumGoodsIssuedNotesRestQuery
  ): Promise<any> {
    return this.post('/goods-issued-notes/sums', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  sumSelectedGoodsReceivedNotes(
    data: GoodsIssuedNotes.Requests.SumGoodsReceivedNotesRestQuery
  ): Promise<any> {
    return this.post('/goods-received-notes/sums', data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteGoodsIssuedNote(goodsIssuedNoteNumber: String | Number): Promise<any> {
    return this.delete('/goods-issued-notes', {
      params: {
        goodsIssuedNoteNumber
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  exportXlsxGoodIssuedNotes(
    goodsIssuedNoteNumber: string
  ): AxiosPromise<BlobPart> {
    return this.get('/goods-issued-notes/export/xlsx', {
      params: {
        goodsIssuedNoteNumber
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      responseType: 'blob'
    })
  }
}
