import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { AppStocksApi } from './app'
import { BackofficeStocksApi } from './backoffice'
import { Queue } from '../../queue'

export class Stocks implements ApiNamespaces {
  app: AppStocksApi
  backoffice: BackofficeStocksApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new AppStocksApi(name, sessionId, requestsQueue, options)
    this.backoffice = new BackofficeStocksApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
