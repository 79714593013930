import { ApiService, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Categories, Products, Suggestions } from '@one/types'
import qs from 'qs'
import Suggestion = Suggestions.Responses.Suggestion
import SearchResultPager = Products.Responses.SearchResultPager
import SearchFilters = Products.Responses.SearchFilters

export class AppCatalogApi extends ApiService {
  getCategories(): AxiosPromise<Array<Categories.Category>> {
    return this.get('/categories/list', {
      cache: {
        ttl: 60 * 1000,
        tags: ['categories', 'auth-optional']
      },
      authentication: Authentication.OPTIONAL,
      aud: Audience.APP
    })
  }

  getCategoriesSearch(
    category?: string,
    query?: string
  ): AxiosPromise<Array<Categories.Category>> {
    return this.get('/categories/search', {
      params: {
        category,
        q: query
      },
      cache: {
        ttl: 60 * 1000,
        tags: ['categories', 'auth-optional']
      },
      authentication: Authentication.OPTIONAL,
      aud: Audience.APP
    })
  }

  getCategoriesSuggestions(
    query: string
  ): AxiosPromise<Array<Categories.Category>> {
    return this.get('/categories/suggest', {
      params: {
        userQuery: query
      },
      cache: {
        ttl: 60 * 1000,
        tags: ['categories', 'auth-optional']
      },
      authentication: Authentication.OPTIONAL,
      aud: Audience.APP
    })
  }

  getSuggestionForQuery(query: string): AxiosPromise<Suggestion> {
    return this.get(`/suggestions`, {
      params: {
        userQuery: query
      },
      authentication: Authentication.OPTIONAL,
      aud: Audience.APP
    })
  }

  getProductsAndFilters(
    productsPage: Products.Requests.ProductsPagination,
    filtersPage: Products.Requests.FiltersPagination
  ): Promise<{
    products: SearchResultPager
    filters: SearchFilters
  }> {
    return Promise.all([
      this.getProducts(productsPage),
      this.getFilters(filtersPage)
    ]).then(([products, filters]) => {
      return {
        products: products.data as SearchResultPager,
        filters: filters.data as SearchFilters
      }
    })
  }

  getProducts(
    page?: Products.Requests.ProductsPagination
  ): AxiosPromise<SearchResultPager> {
    return this.get('/products/search/items', {
      params: {
        category: page?.category ?? undefined,
        sortCriteria: page?.sortCriteria ?? undefined,
        price: page?.price ?? undefined,
        filtersQuery: page?.filtersQuery ?? undefined,
        q: page?.q ?? '*',
        pageSize: page?.pageSize,
        pageNumber: page?.pageNumber
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.OPTIONAL,
      aud: Audience.APP
    })
  }

  getFilters(
    page?: Products.Requests.FiltersPagination
  ): AxiosPromise<SearchFilters> {
    return this.get('/products/search/filters/', {
      params: {
        category: page?.category ?? undefined,
        filtersQuery: page?.filtersQuery ?? undefined,
        q: page?.q ?? undefined,
        price: page?.price ?? undefined
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.OPTIONAL,
      aud: Audience.APP
    })
  }

  getProduct(id: string): AxiosPromise<Products.Product> {
    return this.get(`/products/by-sku`, {
      params: {
        id
      },
      cache: {
        tags: ['product', 'auth-optional']
      },
      authentication: Authentication.OPTIONAL,
      aud: Audience.APP
    })
  }

  getProductBySlug(slug: string): AxiosPromise<Products.Product> {
    return this.get(`/products/by-slug`, {
      params: {
        slug
      },
      cache: {
        tags: ['product', 'auth-optional']
      },
      authentication: Authentication.OPTIONAL,
      aud: Audience.APP
    })
  }

  getProductsDefaultSorting(): AxiosPromise<
    Products.Responses.DefaultSortOptions
  > {
    return this.get('/products/sorting')
  }

  getProductsDisplayMode(): AxiosPromise<
    Products.Responses.ProductsListingConfiguration
  > {
    return this.get('/products/listing-configuration')
  }

  getProductsListById(
    allowMissing: boolean,
    id: Array<string>
  ): AxiosPromise<Array<Products.Product>> {
    return this.get('/products/list-by-sku', {
      params: {
        allowMissing,
        id
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.OPTIONAL,
      aud: Audience.APP
    })
  }

  getProductsListByIdOrIndex(
    idOrIndex: Array<string>
  ): AxiosPromise<Array<Products.Product>> {
    return this.get('/products/list-by-id-or-index', {
      params: {
        idOrIndex
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.OPTIONAL,
      aud: Audience.APP
    })
  }
}
