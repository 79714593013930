import {
  ApiNamespaces,
  ApiService,
  IQueuedRequest,
  OneAPIOptions
} from '../../common'
import { BackofficeNotifApi } from './backoffice'
import { Queue } from '../../queue'

export class Notif implements ApiNamespaces {
  app: ApiService
  backoffice: BackofficeNotifApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new ApiService(name, sessionId, requestsQueue, options)
    this.backoffice = new BackofficeNotifApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
