import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import qs from 'qs'
import { Pagination, Tasks } from '@one/types'

export class BackofficeTasksApi extends ApiServiceWithSettings {
  getTasks(
    params: Tasks.Tasks.TaskFilteringQuery
  ): AxiosPromise<Pagination.Pager<Tasks.Tasks.Task>> {
    return this.get('/tasks', {
      params: {
        q: params.q,
        categories: params.categories,
        statuses: params.statuses,
        assignedToUser: params.assignedToUser,
        createdByUser: params.createdByUser,
        watchers: params.watchers,
        clients: params.clients,
        offers: params.offers,
        salesOrders: params.salesOrders,
        warehouseDocuments: params.warehouseDocuments,
        salesDocuments: params.salesDocuments,
        url: params.url,
        startDate: params.startDate,
        dueDate: params.dueDate,
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
        onlyUnread: params.onlyUnread,
        onlyImportant: params.onlyImportant
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }

  getTask(id: number): AxiosPromise<Tasks.Tasks.Task> {
    return this.get(`/tasks/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createTask(task: Tasks.Tasks.CreateTaskDto): AxiosPromise<Tasks.Tasks.Task> {
    return this.post('/tasks', task, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateTask(
    taskId: number,
    task: Tasks.Tasks.UpdateTaskDto
  ): AxiosPromise<void> {
    return this.patch(`/tasks/${taskId}`, task, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteTask(id: number): AxiosPromise<void> {
    return this.delete(`/tasks/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getUnreadTasksList(): AxiosPromise<
    Array<Pick<Tasks.Tasks.UnreadTask, 'taskId' | 'createDate'>>
  > {
    return this.get('/tasks/unread-list', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  setTaskAsRead(taskId: number): AxiosPromise<void> {
    return this.post(`/tasks/${taskId}/read`, null, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getStatuses(): AxiosPromise<Array<Tasks.Statuses.Status>> {
    return this.get('/statuses', {
      authentication: Authentication.PUBLIC,
      aud: Audience.BACKOFFICE
    })
  }

  getCategories(
    pageNumber: number,
    pageSize: number,
    withDeactivated: boolean
  ): AxiosPromise<Pagination.Pager<Tasks.Categories.Category>> {
    return this.get('/categories', {
      params: {
        pageNumber,
        pageSize,
        ...(withDeactivated ? { withDeactivated } : {})
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getCategory(id: number): AxiosPromise<Tasks.Categories.Category> {
    return this.get(`/categories/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createCategory(
    category: Tasks.Categories.CreateCategoryDto
  ): AxiosPromise<Tasks.Categories.Category> {
    return this.post('/categories', category, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateCategory(
    categoryId: string,
    category: Tasks.Categories.UpdateCategoryDto
  ): AxiosPromise<Tasks.Categories.Category> {
    return this.patch(`/categories/${categoryId}`, category, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  removeCategory(categoryId: number): AxiosPromise<void> {
    return this.delete(`/categories/${categoryId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  activateCategory(categoryId: number): AxiosPromise<void> {
    return this.post(`/categories/${categoryId}/activate`, null, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deactivateCategory(categoryId: number): AxiosPromise<void> {
    return this.post(`/categories/${categoryId}/deactivate`, null, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createComment(
    comment: Tasks.Comments.CreateCommentDto
  ): AxiosPromise<Tasks.Comments.Comment> {
    return this.post('/comments', comment, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateComment(
    commentId: string,
    comment: Tasks.Comments.Comment
  ): AxiosPromise<Tasks.Comments.Comment> {
    return this.patch(`/comments/${commentId}`, comment, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteComment(commentId: string): AxiosPromise<void> {
    return this.delete(`/comments/${commentId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getComments(
    pageNumber: number,
    pageSize: number,
    taskId: number
  ): AxiosPromise<Pagination.Pager<Tasks.Comments.Comment>> {
    return this.get(`/comments/${taskId}`, {
      params: {
        pageNumber,
        pageSize
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getCategoriesSuggestions(
    query: string
  ): AxiosPromise<Array<Tasks.Categories.Category>> {
    return this.get('/categories/suggest', {
      params: {
        userQuery: query
      },
      cache: {
        ttl: 60 * 1000,
        tags: ['categories', 'auth-optional']
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createAttachment(attachment: FormData): AxiosPromise<void> {
    return this.post('/attachments', attachment, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  downloadAttachment(
    attachment: Tasks.Attachments.DownloadAttachmentDto
  ): AxiosPromise<string> {
    return this.get('/attachments/download', {
      params: {
        id: attachment.id,
        name: attachment.name,
        taskId: attachment.taskId
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteAttachment(attachmentId: string): AxiosPromise<void> {
    return this.delete(`/attachments/${attachmentId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAttachments(
    taskId: number
  ): AxiosPromise<Array<Tasks.Attachments.Attachment>> {
    return this.get(`/attachments/${taskId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAllImportantTasks(): AxiosPromise<
    Array<Pick<Tasks.Tasks.ImportantTask, 'taskId' | 'createDate'>>
  > {
    return this.get('/tasks/important-list', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  setTaskAsNotImportant(taskId: number): AxiosPromise<void> {
    return this.post(`/tasks/${taskId}/unset-important`, null, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  setTaskAsImportant(taskId: number): AxiosPromise<void> {
    return this.post(`/tasks/${taskId}/set-important`, null, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
