import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Stocks } from '@one/types'
import qs from 'qs'
import Warehouse = Stocks.Warehouse
import ProductsStocks = Stocks.Responses.ProductsStocks
import ProductStocks = Stocks.Responses.ProductStocks

export class BackofficeStocksApi extends ApiServiceWithSettings {
  getStocksForProductsAndWarehouses(
    products: Array<string>,
    warehouses: Array<string>
  ): AxiosPromise<ProductsStocks> {
    return this.get('/products/stocks', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      params: {
        skus: products && products.length > 0 ? products : undefined,
        warehouses: warehouses && warehouses.length > 0 ? warehouses : undefined
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      cache: {
        tags: ['stocks']
      }
    })
  }

  getStocksForProduct(
    productId: string,
    warehouses?: Array<string>
  ): AxiosPromise<ProductStocks> {
    return this.get('/products/stocks-for-one-product', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      params: {
        sku: productId,
        warehouses
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      cache: {
        tags: ['stocks']
      }
    })
  }
  getWarehouses(): AxiosPromise<Array<Warehouse>> {
    return this.get('/warehouses', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE,
      cache: {
        ttl: 60 * 100,
        tags: ['warehouses-bo']
      }
    })
  }

  getWarehouse(warehouseId: string): AxiosPromise<Warehouse> {
    return this.get(`/warehouses/${warehouseId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateWarehouses(warehouse: Warehouse): AxiosPromise<string> {
    this.invalidate(['warehouses-bo'])
    return this.put('/warehouses', warehouse, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
