import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { BackofficePrintsApi } from './backoffice'
import { AppPrintsApi } from './app'
import { Queue } from '../../queue'

export class Prints implements ApiNamespaces {
  app: AppPrintsApi
  backoffice: BackofficePrintsApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new AppPrintsApi(name, sessionId, requestsQueue, options)

    this.backoffice = new BackofficePrintsApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
