import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { PaymentMethodCommand } from '@one/types'

export class BackofficePaymentMethodsApi extends ApiServiceWithSettings {
  getPaymentMethods(): AxiosPromise<PaymentMethodCommand<any>[]> {
    return this.get('/payment-methods', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createPaymentMethod(command: PaymentMethodCommand<any>): AxiosPromise<void> {
    return this.post('/payment-methods', command, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updatePaymentMethod({
    command,
    paymentMethodId
  }: {
    command: PaymentMethodCommand<any>
    paymentMethodId: string
  }): AxiosPromise<void> {
    return this.put(`/payment-methods/${paymentMethodId}`, command, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deletePaymentMethod(paymentMethodId: string): AxiosPromise<void> {
    return this.delete(`/payment-methods/${paymentMethodId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
