import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Notif } from '@one/types'

export class BackofficeNotifApi extends ApiServiceWithSettings {
  getEmailTemplates(): AxiosPromise<
    Array<Notif.Backoffice.Response.EmailTemplate>
  > {
    return this.get('/email/template', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getLatestEmailTemplate(
    type: Notif.Backoffice.EmailTemplateTypeEnum
  ): AxiosPromise<Notif.Backoffice.Response.EmailTemplate> {
    return this.get(`/email/template/${type}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  createNewTemplateVersion(
    data: Notif.Backoffice.Request.CreateEmailTemplateVersion,
    type: Notif.Backoffice.EmailTemplateTypeEnum
  ): AxiosPromise<void> {
    return this.post(`/email/template/${type}`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getModelSchema<T>(
    type: Notif.Backoffice.EmailTemplateTypeEnum
  ): AxiosPromise<T> {
    return this.get(`/email/template/${type}/model`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  renderTemplateWithExampleData(
    data: Notif.Backoffice.Request.CreateEmailTemplateVersion,
    type: Notif.Backoffice.EmailTemplateTypeEnum
  ): AxiosPromise<void> {
    return this.post(`/email/template/${type}/render-example`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  sendTest(
    data: Notif.Backoffice.Request.SendTestEmailRequest,
    type: Notif.Backoffice.EmailTemplateTypeEnum
  ): AxiosPromise<void> {
    return this.post(`/email/template/${type}/send-test`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getEmailTemplateTypes(): AxiosPromise<
    Array<Notif.Backoffice.EmailTemplateTypeEnum>
  > {
    return this.get(`/email/template/types`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
