import {
  Store,
  ActionTree,
  GetterTree,
  MutationTree,
  ActionContext,
} from 'vuex'

import difference from 'lodash-es/difference'
import includes from 'lodash-es/includes'
import some from 'lodash-es/some'
const Cookieparser = require('cookieparser')
const jwtDecode = require('jwt-decode')
import Cookies from 'js-cookie'
import {
  isValid,
  toDate,
  isBefore,
  differenceInMilliseconds,
  fromUnixTime,
} from 'date-fns'

import { Vue } from 'vue-property-decorator'

interface AuthState {
  accessToken: string | null
  refreshToken: string | null
  totpToken: string | null
  isLoginModalShown: boolean
  sub: string
  exp: string
  iat: string
  iss: string
  org: string
  rol: Array<string>
}

interface Credentials {
  [key: string]: string
}

export interface AuthorizationTokens {
  [key: string]: string
}

interface AuthHandlers {
  login?(credentials: Credentials): Promise<AuthorizationTokens>
  totpLogin?(credentials: Credentials): Promise<AuthorizationTokens>
  refreshTokens?(token: string): Promise<AuthorizationTokens>
  logout?(): Promise<void>
}

const mt = {
  AUTHORIZE: 'AUTHORIZE',
  HIDE_LOGIN_MODAL: 'HIDE_LOGIN_MODAL',
  SHOW_LOGIN_MODAL: 'SHOW_LOGIN_MODAL',
  LOGOUT: 'LOGOUT',
}

class AuthModule {
  namespaced: boolean = true
  // @ts-ignore
  actions: ActionTree<AuthState, any> = {
    refreshTokens({ commit }: ActionContext<AuthState, any>) {
      return
    },
  }
  getters: GetterTree<AuthState, any> = {}
  mutations: MutationTree<AuthState> = {
    [mt.AUTHORIZE](state: AuthState, tokens: AuthState) {
      state.accessToken = tokens.accessToken
      state.refreshToken = tokens.refreshToken
      state.totpToken = tokens.totpToken
      state.sub = tokens.sub
      state.exp = tokens.exp
      state.iat = tokens.iat
      state.iss = tokens.iss
      state.org = tokens.org
      state.rol = tokens.rol
    },
    [mt.HIDE_LOGIN_MODAL](state: AuthState) {
      state.isLoginModalShown = false
    },
    [mt.SHOW_LOGIN_MODAL](state: AuthState) {
      state.isLoginModalShown = true
    },
    [mt.LOGOUT](state: AuthState) {
      state.isLoginModalShown = false
      state.accessToken = null
      state.refreshToken = null
      state.totpToken = null
      state.sub = ''
      state.exp = ''
      state.iat = ''
      state.iss = ''
      state.org = ''
      state.rol = []
    },
  }

  state = () => {
    return {
      accessToken: null,
      refreshToken: null,
      totpToken: null,
      isLoginModalShown: false,
      sub: '',
      exp: '',
      iat: '',
      iss: '',
      org: '',
      rol: '',
    }
  }
}

export interface AuthServiceOptions {
  accessTokenName?: string
  refreshTokenName?: string
  totpTokenName?: string
  stateName?: string
  registerState?: boolean
  renewalTimeBuffer?: number
  store: any
  useCookies?: boolean
  handlers: AuthHandlers
  onInit?(service: AuthService): void
}

export class AuthService implements AuthServiceOptions {
  store!: Store<any>

  renewalTimeout: any = null

  accessTokenName: string = 'accessToken'
  refreshTokenName: string = 'refreshToken'
  totpTokenName: string = 'totpToken'
  registerState: boolean = true
  stateName: string = 'auth'
  renewalTimeBuffer: number = 5000
  useCookies: boolean = false

  storage: any = null
  handlers: AuthHandlers = {}

  _events: Vue = new Vue()

  public $on = this._events.$on
  public $off = this._events.$off
  private $once = this._events.$once
  private $emit = this._events.$emit

  constructor(options?: AuthServiceOptions) {
    if (options) {
      this.stateName = options.stateName || this.stateName
      this.accessTokenName = options.accessTokenName || this.accessTokenName
      this.refreshTokenName = options.refreshTokenName || this.refreshTokenName
      this.totpTokenName = options.totpTokenName || this.totpTokenName
      this.useCookies =
        typeof options.useCookies !== 'undefined'
          ? options.useCookies
          : this.useCookies
      this.storage = typeof localStorage !== 'undefined' ? localStorage : null
      this.registerState =
        typeof options.registerState !== 'undefined'
          ? options.registerState
          : this.registerState
      this.store = options.store
      if (!this.store) {
        throw Error('No store provided')
      }
      this.renewalTimeBuffer =
        options.renewalTimeBuffer || this.renewalTimeBuffer
      if (this.registerState) {
        this.registerStateModule()
      }
      this.handlers = options.handlers ? options.handlers : this.handlers
      if (typeof window !== 'undefined' || process.client) {
        // When we have session restored on SSR side this will trigger the renewal
        this.tryToSetRenewalTimeout()
      }
    }
  }

  private registerStateModule(): void {
    // register state in vuex
    // @ts-ignore
    this.store.registerModule(this.stateName, new AuthModule(), {
      preserveState: !!this.state,
    })
  }

  private commit(mutation: string, payload?: any): void {
    return this.store.commit(`${this.stateName}/${mutation}`, payload)
  }

  private dispatch(action: string, payload?: any): Promise<any> {
    return this.store.dispatch(`${this.stateName}/${action}`, payload)
  }

  private get state() {
    // @ts-ignore
    return this.store.state[this.stateName] as T
  }

  private getMsDiff() {
    return differenceInMilliseconds(
      fromUnixTime(Number(this.state.exp!)),
      new Date()
    )
  }

  public tryToSetRenewalTimeout() {
    if (this.isAuthenticated) {
      this.setRenewalTimeout()
    }
  }

  private setRenewalTimeout() {
    this.clearTimeoutAndInterval()
    let timeoutCount: number =
      this.renewalTimeBuffer < this.getMsDiff()
        ? this.getMsDiff() - this.renewalTimeBuffer
        : this.getMsDiff()
    this.renewalTimeout = setTimeout(
      () => this.refreshTokens(this.state.refreshToken),
      timeoutCount
    )
  }

  private clearTimeoutAndInterval() {
    clearTimeout(this.renewalTimeout)
  }

  public static isTokenValid(token: string | null): boolean {
    try {
      if (!token) {
        return false
      }
      let exp = jwtDecode(token).exp
      const expMoment = toDate(exp * 1000)
      if (isValid(expMoment)) {
        return isBefore(new Date(), expMoment)
      }
      return true
    } catch (e) {
      console.log('Token is invalid', e)
      return false
    }
  }

  private removeTokenFromStorage = (type: string): void => {
    if (this.useCookies) {
      Cookies.remove(type)
    }
    if (this.storage) {
      this.storage.removeItem(type)
    }
  }

  private setTokenInStorage = (type: string, token: string): void => {
    if (this.useCookies) {
      Cookies.set(type, token, { expires: 60 })
    }
    if (this.storage) {
      this.storage.setItem(type, token)
    }
  }

  public get authorizationToken() {
    return this.state.accessToken
  }

  public get refreshToken(): string | null {
    return this.state.refreshToken
  }

  public get expireAt() {
    return this.state.exp
  }

  public get isTokenExpired(): boolean {
    let exp = this.expireAt!
    const expMoment = toDate(Number(exp))
    if (isValid(expMoment)) {
      return isBefore(new Date(), expMoment)
    }
    return true
  }

  public get isAuthenticated(): boolean {
    return !!this.authorizationToken && !this.isTokenExpired
  }

  private reinitializeSession(
    tokens: AuthorizationTokens
  ): Promise<any> | null {
    if (AuthService.isTokenValid(tokens.accessToken)) {
      // Tokens are valid - we can log in
      return this.authorize(tokens)
    } else {
      if (AuthService.isTokenValid(tokens.refreshToken)) {
        // Auth token was invalid but refresh token is valid -> refreshing and login
        return this.refreshTokens(tokens.refreshToken)
          .then((success) => {
            return Promise.resolve(success)
          })
          .catch((e) => {
            // We dont want to stop app from working so resolve here
            return Promise.resolve(null)
          })
      }
      return null
    }
  }

  public async initSessionFromStorage(): Promise<any | null> {
    return this.reinitializeSession({
      accessToken: this.storage.getItem(this.accessTokenName),
      refreshToken: this.storage.getItem(this.refreshTokenName),
    })
  }

  public async initSessionFromCookies(request: any): Promise<any | null> {
    return this.reinitializeSession({
      accessToken:
        request?.headers?.cookie &&
        Cookieparser.parse(request.headers.cookie)[this.accessTokenName],
      refreshToken:
        request?.headers?.cookie &&
        Cookieparser.parse(request.headers.cookie)[this.refreshTokenName],
    })
  }

  public tryToRefreshAccessToken() {
    if (!AuthService.isTokenValid(this.authorizationToken)) {
      if (AuthService.isTokenValid(this.refreshToken)) {
        return this.refreshTokens(this.refreshToken!)
          .then((success) => {
            return Promise.resolve(true)
          })
          .catch((e) => {
            return Promise.reject({
              code: 'REFRESH_FAILED',
              err: e,
            })
          })
      }
      return Promise.reject({
        code: 'REFRESH_TOKEN_INVALID',
      })
    }
    return Promise.reject({
      code: 'ACCESS_TOKEN_VALID',
    })
  }

  public async authorize(auth: AuthorizationTokens) {
    let fullPayload = {
      ...auth,
    }
    if (auth.totpToken) {
      this.removeTokenFromStorage(this.accessTokenName)
      this.removeTokenFromStorage(this.refreshTokenName)
      this.setTokenInStorage(this.totpTokenName, auth.totpToken)
      this.setTokenInStorage(this.accessTokenName, auth.accessToken)
      this.setTokenInStorage(this.refreshTokenName, auth.refreshToken)
      this.commit(mt.AUTHORIZE, fullPayload)
    } else {
      const decoded = this.decodeAccessToken(auth.accessToken)
      fullPayload = {
        ...auth,
        ...decoded,
      }
      this.commit(mt.AUTHORIZE, fullPayload)
      this.removeTokenFromStorage(this.accessTokenName)
      this.removeTokenFromStorage(this.refreshTokenName)
      this.setTokenInStorage(this.accessTokenName, auth.accessToken)
      this.setTokenInStorage(this.refreshTokenName, auth.refreshToken)
    }

    if (typeof window !== 'undefined' || process.client) {
      // Only in browser set token renewal
      this.tryToSetRenewalTimeout()
    }

    this.$emit('auth:authorized', fullPayload)
    return fullPayload
  }

  public decodeAccessToken = (token: string) => {
    if (AuthService.isTokenValid(token)) {
      const decoded = jwtDecode(token)
      if (typeof decoded['exp'] === 'undefined') {
        throw Error('Token does not contain EXP time!')
      }
      return decoded
    }
    throw Error('Token is invalid!')
  }

  public logout = async () => {
    this.$emit('auth:beforeLogout')
    if (
      typeof this.handlers.logout !== 'undefined' &&
      typeof this.handlers.logout === 'function'
    ) {
      await this.handlers.logout()
    }
    this.removeTokenFromStorage(this.refreshTokenName)
    this.removeTokenFromStorage(this.accessTokenName)
    this.removeTokenFromStorage(this.totpTokenName)
    this.clearTimeoutAndInterval()
    this.commit(mt.LOGOUT)
    this.$emit('auth:logout')
  }

  public showLoginModal() {
    this.commit(mt.SHOW_LOGIN_MODAL)
    this.$emit('auth:loginModalStateChange', true)
  }

  public hideLoginModal() {
    this.commit(mt.HIDE_LOGIN_MODAL)
    this.$emit('auth:loginModalStateChang', false)
  }

  public async checkAuthorization(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      if (!this.isAuthenticated) {
        this.showLoginModal()
        const detachEvents = () => {
          this.$off('auth:loginModalStateChange', onModalHidden)
          this.$off('auth:authorized', onUserLoggedIn)
        }
        const onUserLoggedIn = () => {
          detachEvents()
          this.hideLoginModal()
          resolve(true)
        }
        const onModalHidden = (isModalVisible: boolean) => {
          if (!isModalVisible) {
            detachEvents()
            resolve(false)
          }
        }
        this.$on('auth:authorized', onUserLoggedIn)
        this.$on('auth:loginModalStateChange', onModalHidden)
      } else {
        resolve(true)
      }
    })
  }

  private innerLoginHandler(credentials: Credentials) {
    return this.dispatch('login', {
      login: credentials.login,
      password: credentials.password,
    })
  }

  private innerRefreshTokens(refreshToken: string) {
    return this.dispatch('refreshTokens', refreshToken)
  }
  // OVERRIDE ACTIONS

  public async login(credentials: Credentials): Promise<any> {
    const handler = this.handlers.login || this.innerLoginHandler
    const tokens: AuthorizationTokens | null = await handler(credentials)
    if (tokens) {
      const ret: any = await this.authorize(tokens)
      if (!ret.totpToken) {
        this.$emit('auth:login')
      }
      return ret
    }
    return Promise.reject()
  }

  public async totpLogin(credentials: Credentials): Promise<any> {
    if (credentials) {
      const ret: any = this.authorize(credentials)
      this.$emit('auth:login')
      return ret
    }
    return Promise.reject()
  }

  public async refreshTokens(refreshToken: string): Promise<any> {
    const handler = this.handlers.refreshTokens || this.innerRefreshTokens
    const newTokens: AuthorizationTokens | null = await handler(refreshToken)
    if (newTokens) {
      const ret = this.authorize(newTokens)
      this.$emit('auth:refreshed')
      return ret
    }
    return Promise.reject()
  }

  public hasPermissions(
    permissions: Array<string>,
    isOneOfPermissions: boolean = false
  ): boolean {
    // tslint:disable-next-line:no-unused-expression
    return !isOneOfPermissions
      ? difference(permissions, this.state.rol).length === 0
      : some(this.state.rol, (el: string) => includes(permissions, el))
  }

  public passwordChangeIsRequired(): boolean {
    return this.hasPermissions(['APP_CHANGE_PASSWORD_REQUIRED'], true)
  }

  public static install(
    Vue: any,
    options: AuthServiceOptions,
    inject: boolean = true
  ) {
    return new Promise(async (resolve) => {
      const service = Vue.observable(new AuthService(options))
      if (inject) {
        Vue.prototype.$auth = service
      }
      if (
        options &&
        typeof options.onInit !== 'undefined' &&
        typeof options.onInit === 'function'
      ) {
        await options.onInit(service)
      }
      resolve(service)
    })
  }
}
