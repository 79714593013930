import { ApiService, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { GoodsIssuedNotes, Pagination } from '@one/types'
import qs from 'qs'
import SumGoodsReceivedNotesAppRestQuery = GoodsIssuedNotes.Requests.SumGoodsReceivedNotesAppRestQuery
import GoodsReceivedNoteSumsRestView = GoodsIssuedNotes.Responses.GoodsReceivedNoteSumsRestView
import GoodsReceivedNoteSimpleRestView = GoodsIssuedNotes.Responses.GoodsReceivedNoteSimpleRestView
import GoodsReceivedNoteRestView = GoodsIssuedNotes.Responses.GoodsReceivedNoteRestView

export class AppGoodsIssuedNotesApi extends ApiService {
  getGoodsIssuedNotes(
    pageNumber: number,
    pageSize: number,
    search: string | null,
    startDate: string | null,
    endDate: string | null,
    sortOrder: GoodsIssuedNotes.State.GoodsIssuedNoteSortingEnum
  ): AxiosPromise<GoodsIssuedNotes.Responses.GoodsIssuedNotePaginated> {
    return this.get('/goods-issued-note/search', {
      params: {
        pageNumber,
        pageSize,
        search: search || null,
        startDate: startDate || null,
        endDate: endDate || null,
        sortOrder
      },
      authentication: Authentication.REQUIRED
    })
  }

  getGoodsIssuedNotePdf(goodsIssuedNoteNumber: string): AxiosPromise<BlobPart> {
    return this.get('/goods-issued-note/pdf', {
      params: {
        goodsIssuedNoteNumber
      },
      authentication: Authentication.REQUIRED,
      responseType: 'blob'
    })
  }

  getGoodsIssuedNote(
    goodsIssuedNoteNumber: string
  ): AxiosPromise<GoodsIssuedNotes.Responses.GoodsIssuedNote> {
    return this.get('/goods-issued-note', {
      params: {
        goodsIssuedNoteNumber
      },
      authentication: Authentication.REQUIRED
    })
  }

  exportGoodsIssuedNote(goodsIssuedNoteNumber: string): AxiosPromise<BlobPart> {
    return this.get('/goods-issued-note/export', {
      params: {
        goodsIssuedNoteNumber
      },
      authentication: Authentication.REQUIRED,
      responseType: 'blob'
    })
  }

  sumSelectedGoodsIssuedNotes(
    goodIssuedNotes: Array<string>
  ): AxiosPromise<GoodsReceivedNoteSumsRestView> {
    return this.get('/goods-issued-note/sums', {
      params: {
        goodIssuedNotes
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.REQUIRED
    })
  }

  getGoodsReceivedNote(
    goodsReceivedNoteNumber: string
  ): AxiosPromise<GoodsReceivedNoteRestView> {
    return this.get('/goods-received-notes', {
      params: {
        goodsReceivedNoteNumber
      },
      authentication: Authentication.REQUIRED
    })
  }

  sumSelectedGoodsReceivedNotes(
    data: SumGoodsReceivedNotesAppRestQuery
  ): AxiosPromise<GoodsReceivedNoteSumsRestView> {
    return this.post('/goods-received-notes/sums', data, {
      authentication: Authentication.REQUIRED
    })
  }

  getGoodsReceivedNotes(
    pageNumber: number,
    pageSize: number,
    search: string | null,
    startDate: string | null,
    endDate: string | null,
    sortOrder: GoodsIssuedNotes.State.GoodsIssuedNoteSortingEnum
  ): AxiosPromise<Pagination.Pager<GoodsReceivedNoteSimpleRestView>> {
    return this.get('/goods-received-notes/search', {
      params: {
        pageNumber,
        pageSize,
        search: search || null,
        startDate: startDate || null,
        endDate: endDate || null,
        sortOrder
      },
      authentication: Authentication.REQUIRED
    })
  }
}
