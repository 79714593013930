import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { AppPricingApi } from './app'
import { BackofficePricingApi } from './backoffice'
import { Queue } from '../../queue'

export class Pricing implements ApiNamespaces {
  app: AppPricingApi
  backoffice: BackofficePricingApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new AppPricingApi(name, sessionId, requestsQueue, options)
    this.backoffice = new BackofficePricingApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
