import { ApiService, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Auth } from '@one/types'

export class AppAuthApi extends ApiService {
  impersonate(email: string): AxiosPromise<Auth.Responses.Token> {
    let encodedEmail = encodeURIComponent(email)
    return this.post(`/session/app-user-token/${encodedEmail}`, null, {
      aud: Audience.INTERNAL
    })
  }
  /**
   * Login by email and password
   * @param data
   */
  authentication(
    data: Auth.Requests.Login
  ): AxiosPromise<Auth.Responses.Token> {
    return this.post('/session/login', data)
  }

  /**
   * Take new tokens by refresh token
   * @param data
   */
  tokenRefresh(
    data: Auth.Requests.RefreshToken
  ): AxiosPromise<Auth.Responses.Token> {
    return this.post(`/session/token`, data)
  }

  /**
   * Reset user password
   * @param data
   */
  resetPassword(data: Auth.Requests.ResetPassword): AxiosPromise<void> {
    return this.post(`/session/password/reset`, data)
  }

  authorities(): AxiosPromise<Auth.Roles.Authorities> {
    return this.get('/authorities', {
      authentication: Authentication.REQUIRED,
      cache: {
        tags: ['authorities']
      }
    })
  }

  fetchRoles(): AxiosPromise<Array<Auth.Roles.Responses.Role>> {
    return this.get('/roles', {
      authentication: Authentication.REQUIRED,
      cache: {
        tags: ['authorities', 'roles']
      }
    })
  }

  createRole(
    data: Auth.Roles.Requests.CreateEditRoleOption
  ): AxiosPromise<void> {
    this.invalidate('authorities')
    return this.post('/roles', data, {
      authentication: Authentication.REQUIRED
    })
  }

  roleById(roleId: string): AxiosPromise<Auth.Roles.Responses.Role> {
    return this.get(`/roles/${roleId}`, {
      authentication: Authentication.REQUIRED,
      cache: {
        tags: ['authorities']
      }
    })
  }

  updateRole(
    payload: Auth.Roles.Requests.UpdateRoleOption
  ): AxiosPromise<void> {
    this.invalidate('authorities')
    return this.put(`/roles/${payload.roleId}`, payload.data, {
      authentication: Authentication.REQUIRED
    })
  }

  deleteRole(roleId: string): AxiosPromise<void> {
    this.invalidate('authorities')
    return this.delete(`/roles/${roleId}`, {
      authentication: Authentication.REQUIRED
    })
  }
}
