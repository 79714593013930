import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { AppPricehelperApi } from './app'
import { BackofficePricehelperApi } from './backoffice'
import { Queue } from '../../queue'

export class Pricehelper implements ApiNamespaces {
  app: AppPricehelperApi
  backoffice: BackofficePricehelperApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new AppPricehelperApi(name, sessionId, requestsQueue, options)
    this.backoffice = new BackofficePricehelperApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
