import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import {
  CartOpenedBackofficeRestCommand,
  ClientLoggedBackofficeRestCommand,
  CreateWebhookRestCommand,
  InterceptedRequestParamsRestView,
  OfferOpenedBackofficeRestCommand,
  Pagination,
  SendExampleRequestRestCommand,
  UpdateWebhookRestCommand,
  WebhookCreateResponse,
  WebhookListParams,
  WebhookRestView,
  WebhookTypeRestView
} from '@one/types'

export class BackofficeWebhooksApi extends ApiServiceWithSettings {
  getWebhooks(
    params: WebhookListParams
  ): AxiosPromise<Pagination.Pager<WebhookRestView>> {
    return this.get('/webhooks', {
      params: {
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
        search: params.search
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getWebhook(id: string): AxiosPromise<WebhookRestView> {
    return this.get(`/webhooks/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getWebhooksTypes(): AxiosPromise<Array<WebhookTypeRestView>> {
    return this.get('/webhooks/types', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  createWebhook(
    webhook: CreateWebhookRestCommand
  ): AxiosPromise<WebhookCreateResponse> {
    return this.post('/webhooks', webhook, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateWebhook(webhook: UpdateWebhookRestCommand): AxiosPromise<void> {
    return this.put(`/webhooks`, webhook, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  deleteWebhook(id: string): AxiosPromise<void> {
    return this.delete(`/webhooks/${id}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  sendTestWebhookRequest(
    webhook: SendExampleRequestRestCommand
  ): AxiosPromise<InterceptedRequestParamsRestView> {
    return this.post(`/webhooks/example`, webhook, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  publishCartOpened(
    payload: CartOpenedBackofficeRestCommand
  ): AxiosPromise<void> {
    return this.post('/webhooks/publish/cart_opened', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  publishClientLogged(
    payload: ClientLoggedBackofficeRestCommand
  ): AxiosPromise<void> {
    return this.post('/webhooks/publish/client_logged', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  publishOfferLogged(
    payload: OfferOpenedBackofficeRestCommand
  ): AxiosPromise<void> {
    return this.post('/webhooks/publish/offer_opened', payload, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
