// Import here Polyfills if needed. Recommended core-js (npm i -D core-js)
// import "core-js/fn/array.find"
import { AuthService } from './plugins/AuthService'
import { Throttle } from './utils/throttle'
import exponentialBackoff from './utils/exponentialBackoff'
import * as commons from './utils/commons'
import * as converters from './utils/converters'
const isNotNull = <T>(value: T): value is NonNullable<typeof value> => !!value

const arrayToObject = (arr: any, keyField: string) =>
  Object.assign({}, ...arr.map((item: any) => ({ [item[keyField]]: item })))

export {
  Throttle,
  isNotNull,
  arrayToObject,
  exponentialBackoff,
  AuthService,
  commons,
  converters,
}
