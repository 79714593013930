import { ApiService, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Documents } from '@one/types'
import qs from 'qs'

export class AppInvoicesApi extends ApiService {
  getDocumentsList(
    pageNumber: number,
    pageSize: number,
    search: string | null,
    startDate: string | null,
    endDate: string | null,
    paymentFilter: Documents.DocumentFilterEnum,
    sortingMode: Documents.SortingModeEnum,
    documentTypes: Array<Documents.DocumentTypeEnum>
  ): AxiosPromise<Documents.DocumentsPagination> {
    return this.get('/accounting-documents', {
      params: {
        pageNumber: pageNumber || 1,
        pageSize: pageSize || 10,
        search: search || null,
        startDate: startDate || null,
        endDate: endDate || null,
        paymentFilter: paymentFilter || Documents.DocumentFilterEnum.ALL,
        sortingMode: sortingMode || Documents.SortingModeEnum.NEWEST_TO_OLDEST,
        documentTypes: documentTypes
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.REQUIRED
    })
  }

  getSingleDocument(
    documentNumber: string
  ): AxiosPromise<Documents.DocumentDto> {
    return this.get(`/accounting-documents/single`, {
      params: {
        number: documentNumber
      },
      authentication: Authentication.REQUIRED
    })
  }

  getDocumentsPaymentData(
    document: Array<string>,
    documentTypes: Array<Documents.DocumentTypeEnum>
  ): AxiosPromise<Documents.DocumentSums> {
    return this.get(`/accounting-documents/sums`, {
      params: {
        document,
        documentTypes
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.REQUIRED
    })
  }

  getAllNumbersDocuments(
    search: string | null,
    paymentFilter: Documents.DocumentFilterEnum,
    startDate: string | null,
    endDate: string | null,
    documentTypes: Array<Documents.DocumentTypeEnum>,
    document: Array<string> | null
  ): AxiosPromise<Documents.DocumentSums> {
    return this.get(`/accounting-documents/sums`, {
      params: {
        search: search || null,
        paymentFilter: paymentFilter || Documents.DocumentFilterEnum.ALL,
        startDate: startDate ? startDate : null,
        endDate: endDate ? endDate : null,
        documentTypes: documentTypes,
        document: document || null
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
      authentication: Authentication.REQUIRED
    })
  }

  exportDocument(documentNumber: string): AxiosPromise<BlobPart> {
    return this.get('/export', {
      params: {
        number: documentNumber
      },
      authentication: Authentication.REQUIRED,
      responseType: 'blob'
    })
  }

  getFileDocument(fileDownloadUrl: string | undefined): AxiosPromise<void> {
    return this.get(`${fileDownloadUrl}`, {
      rawUrl: true,
      injectToken: false,
      injectTenant: false,
      responseType: 'blob'
    })
  }
}
