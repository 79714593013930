import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { AppCmsApi } from './app'
import { BackofficeCmsApi } from './backoffice'
import { Queue } from '../../queue'

export class Cms implements ApiNamespaces {
  app: AppCmsApi
  backoffice: BackofficeCmsApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new AppCmsApi(name, sessionId, requestsQueue, options)
    this.backoffice = new BackofficeCmsApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
