import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { AppProductsApi } from './app'
import { BackofficeProductsApi } from './backoffice'
import { Queue } from '../../queue'

export class Products implements ApiNamespaces {
  app: AppProductsApi
  backoffice: BackofficeProductsApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new AppProductsApi(name, sessionId, requestsQueue, options)
    this.backoffice = new BackofficeProductsApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
