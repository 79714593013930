import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'

export class BackofficeSocksApi extends ApiServiceWithSettings {
  getTicket(): AxiosPromise<string> {
    return this.get('/ticket', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
}
