const exponentialBackoff = (
  max: number,
  delay: number,
  toTry: Function,
  success: Function,
  fail: Function,
  info: Function
) => {
  toTry()
    .then((result: unknown) => success(result))
    .catch((error: unknown) => {
      if (max === 0) {
        return fail(error)
      }
      info(delay, max)
      setTimeout(() => {
        // eslint-disable-next-line no-param-reassign
        exponentialBackoff(--max, delay * 2, toTry, success, fail, info)
      }, delay * 1000)
    })
}

export default exponentialBackoff
