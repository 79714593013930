import { AxiosPromise } from 'axios'
import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import {
  BonusLevelNameView,
  CalculateBonusPriceCommand,
  CalculateBonusPricesView,
  MinimalPricesView,
  Pagination
} from '@one/types'

export class BackofficePricehelperApi extends ApiServiceWithSettings {
  getMinimalPricesLevels(
    pageNumber?: number,
    pageSize?: number
  ): AxiosPromise<Pagination.Pager<Array<string>>> {
    return this.get('/minimal-prices/levels', {
      params: {
        pageNumber: pageNumber || 1,
        pageSize: pageSize || 999
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getMinimalPrices(
    skuIds: Array<string>,
    branchId?: string | undefined
  ): AxiosPromise<MinimalPricesView> {
    return this.post(
      '/minimal-prices',
      {
        skuIds,
        clientDefault: branchId
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  getBonusLevelNames(): AxiosPromise<BonusLevelNameView> {
    return this.get('/bonuses/levels', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  calculateBonuses(
    bonusPrices: Record<string, CalculateBonusPriceCommand>
  ): AxiosPromise<CalculateBonusPricesView> {
    return this.post(
      '/bonuses/calculate',
      {
        bonusPrices
      },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
}
