import { ApiNamespaces, IQueuedRequest, OneAPIOptions } from '../../common'
import { AppGoodsIssuedNotesApi } from './app'
import { BackofficeGoodsIssuedNotesApi } from './backoffice'
import { Queue } from '../../queue'

export class GoodsIssuedNotes implements ApiNamespaces {
  app: AppGoodsIssuedNotesApi
  backoffice: BackofficeGoodsIssuedNotesApi
  constructor(
    name: string,
    sessionId: string,
    requestsQueue: Queue<IQueuedRequest>,
    options?: OneAPIOptions
  ) {
    this.app = new AppGoodsIssuedNotesApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
    this.backoffice = new BackofficeGoodsIssuedNotesApi(
      name,
      sessionId,
      requestsQueue,
      options
    )
  }

  invalidateAll() {
    this.app.invalidateAll()
    this.backoffice.invalidateAll()
  }
}
