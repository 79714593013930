import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Branches, Pagination } from '@one/types'

export class BackofficeBranchesApi extends ApiServiceWithSettings {
  getBranches({
    pageNumber,
    pageSize
  }: {
    pageNumber: number
    pageSize: number
  }): AxiosPromise<Pagination.Pager<Branches.Backoffice.Response.BranchView>> {
    return this.get('/branches', {
      params: {
        pageNumber,
        pageSize
      },
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  getBranchById(
    branchId: string
  ): AxiosPromise<Branches.Backoffice.Response.BranchView> {
    return this.get(`/branches/${branchId}`, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  createBranch(
    data: Branches.Backoffice.Request.BranchUpdateRestCommand
  ): AxiosPromise<void> {
    return this.post(`/branches`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  updateBranch(
    branchId: string,
    data: Branches.Backoffice.Request.BranchUpdateRestCommand
  ): AxiosPromise<void> {
    return this.put(`/branches/${branchId}`, data, {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }
  activateBranch(branchId: string): AxiosPromise<void> {
    return this.patch(
      `/branches/${branchId}/activate`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
  deactivateBranch(branchId: string): AxiosPromise<void> {
    return this.patch(
      `/branches/${branchId}/deactivate`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
}
