import { ApiServiceWithSettings, Audience, Authentication } from '../../common'
import { AxiosPromise } from 'axios'
import { Plugins } from '@one/types'
import ActivePlugin = Plugins.ActivePlugin
import ListedPlugin = Plugins.ListedPlugin
import TokenResponse = Plugins.TokenResponse

export class BackofficePluginsApi extends ApiServiceWithSettings {
  getActivePlugins(): AxiosPromise<Array<ActivePlugin>> {
    return this.get('/active-plugins', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  getAllPlugins(): AxiosPromise<Array<ListedPlugin>> {
    return this.get('/list-plugins', {
      authentication: Authentication.REQUIRED,
      aud: Audience.BACKOFFICE
    })
  }

  updateSettings(pluginId: string, settings: unknown): AxiosPromise<void> {
    return this.post(
      `/update-settings/${pluginId}`,
      { settings },
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }

  createToken(pluginId: string): AxiosPromise<TokenResponse> {
    return this.post(
      '/create-token',
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE,
        params: {
          pluginId
        }
      }
    )
  }

  generateAuthWithOneToken(pluginId: string): AxiosPromise<TokenResponse> {
    return this.post(
      `/auth-with-one/${pluginId}/generateToken`,
      {},
      {
        authentication: Authentication.REQUIRED,
        aud: Audience.BACKOFFICE
      }
    )
  }
}
